import { renderThreeDsChallenge } from './renderThreeDsChallenge';
import { displayThreeDsModal } from './displayThreeDsModal';
import { handleThreeDsChallengeNotification } from './handleThreeDsChallengeNotification';
import { ChallengeAction, PaymentSession, RyftApiError } from '../../types';

export async function handleThreeDsChallengeAction(
  challenge: ChallengeAction,
  afterThreeDsMethod: boolean,
  publicKey: string,
  clientSecret: string,
  accountId?: string
): Promise<PaymentSession | RyftApiError> {
  if (afterThreeDsMethod) {
    renderThreeDsChallenge(challenge.acsUrl, challenge.cReq);
  } else {
    displayThreeDsModal(undefined, challenge.acsUrl, challenge.cReq);
  }
  return await handleThreeDsChallengeNotification(publicKey, clientSecret, accountId);
}
