﻿import { Subscription } from '../types';

export function validateSubscriptionJson(rawJson: string, paymentType?: string): boolean {
  try {
    const subscription: Subscription = JSON.parse(rawJson);
    return 'price' in subscription && paymentType === 'Recurring';
  } catch {
    return false;
  }
}
