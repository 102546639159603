import { InitParams, PaymentSession } from '../../types';
import { createApplePayButton, isApplePayAvailable, storeWalletDetails } from '../../utils';

export async function initApplePay(
  initParams: InitParams,
  paymentSession?: PaymentSession
): Promise<HTMLButtonElement | undefined> {
  if (!initParams.applePayConfig || !paymentSession) {
    return undefined;
  }
  const applePayAvailable = await isApplePayAvailable();
  if (!applePayAvailable) {
    console.warn(`ApplePay is not available on this device`);
    return undefined;
  }

  return createApplePayButton(
    initParams.applePayConfig!,
    initParams.paymentType,
    initParams.localisationConfig.bcp47LanguageTag,
    storeWalletDetails(initParams.usage, initParams.paymentType),
    paymentSession!,
    initParams.publicKey,
    initParams.clientSecret!,
    initParams.accountId
  );
}
