import { waitForEventWithTimeout } from './waitForEventWithTimeout';
import { sendExceptionToSentry } from '../../service';

export interface ChallengeNotification {
  transStatus: string;
  threeDSServerTransID?: string;
}

export function waitForThreeDsChallengeNotification(
  timeout: number
): Promise<ChallengeNotification> {
  const threeDsCancelledEventType = 'ryft-threeDsChallengeCancelled';

  function predicate(event: MessageEvent): boolean {
    try {
      if (event.type === threeDsCancelledEventType) {
        return true;
      }
      const eventData = event.data;
      if (typeof eventData === 'string') {
        const notification = JSON.parse(eventData);
        return 'transStatus' in notification;
      }
      return false;
    } catch (error) {
      sendExceptionToSentry(error);
      return false;
    }
  }

  function mapEvent(event: MessageEvent): ChallengeNotification {
    if (event.type === threeDsCancelledEventType) {
      return { transStatus: 'N' };
    }
    return JSON.parse(event.data) as ChallengeNotification;
  }

  return waitForEventWithTimeout<ChallengeNotification>(
    window,
    'message',
    timeout,
    predicate,
    mapEvent
  );
}
