﻿export const API_MAJOR_VERSION: number = 2;
export const API_MINOR_VERSION: number = 0;
export const PAYMENT_METHOD_CARD: google.payments.api.PaymentMethodType = 'CARD';
export const ALLOWED_AUTH_METHODS: google.payments.api.CardAuthMethod[] = [
  'PAN_ONLY',
  'CRYPTOGRAM_3DS',
];
export const ALLOWED_CARD_NETWORKS: google.payments.api.CardNetwork[] = [
  'VISA',
  'MASTERCARD',
  'AMEX',
];
export function getBillingAddressParameters(
  billingAddressRequired: boolean
): google.payments.api.BillingAddressParameters | undefined {
  return billingAddressRequired ? { format: 'FULL' } : undefined;
}
