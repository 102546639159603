﻿import { InternalAttemptPaymentRequest } from '../../types';

export function createApplePayAttemptPaymentRequest(
  clientSecret: string,
  applePayPaymentAuthorizedEvent: ApplePayJS.ApplePayPaymentAuthorizedEvent,
  storeWalletDetails: boolean
): InternalAttemptPaymentRequest {
  const applePayTokenPaymentData = applePayPaymentAuthorizedEvent.payment.token.paymentData;
  const base64EncodedApplePayTokenPaymentData = window.btoa(
    JSON.stringify(applePayTokenPaymentData)
  );
  const attemptPaymentRequest: InternalAttemptPaymentRequest = {
    clientSecret,
    walletDetails: {
      type: 'ApplePay',
      applePayToken: base64EncodedApplePayTokenPaymentData,
    },
    paymentMethodOptions: {
      store: storeWalletDetails,
    },
  };
  const billingAddress = applePayPaymentAuthorizedEvent.payment.billingContact;
  const firstName = billingAddress?.givenName || undefined;
  let lastName = billingAddress?.familyName || undefined;
  if (firstName && !lastName) {
    const nameArr = firstName.split(' ');
    lastName = nameArr[nameArr.length - 1] || undefined;
  }
  const addressLines = billingAddress?.addressLines || [];
  const lineOne = addressLines.length > 0 ? addressLines[0] : undefined;
  const lineTwo = addressLines.length > 1 ? addressLines[1] : undefined;
  const city = billingAddress?.locality || undefined;
  const region = billingAddress?.administrativeArea || undefined;
  const postalCode = billingAddress?.postalCode;
  const country = billingAddress?.countryCode;
  if (postalCode && country) {
    attemptPaymentRequest.billingAddress = {
      firstName,
      lastName,
      lineOne,
      lineTwo,
      city,
      region,
      postalCode,
      country,
    };
  }
  const email = applePayPaymentAuthorizedEvent.payment.shippingContact?.emailAddress;
  if (email) {
    attemptPaymentRequest.customerDetails = {
      email,
    };
  }
  return attemptPaymentRequest;
}
