import { IFrameInitParams, InitParams } from '../types';

export function constructIframeInitParams(initParams: InitParams): string {
  const iframeParams: IFrameInitParams = {
    publicKey: initParams.publicKey,
    clientSecret: initParams.clientSecret,
    accountId: initParams.accountId,
    localisation: initParams.localisationConfig,
    style: initParams.styles,
    allowPaymentMethodStorage: initParams.allowPaymentMethodStorage,
    usage: initParams.usage,
    paymentType: initParams.paymentType,
    collectNameOnCard: initParams.fieldCollectionConfig.collectNameOnCard,
    manuallyHandleActions: initParams.manuallyHandleActions,
    parentUrl: window.location.href,
    googlePayConfig: initParams.googlePayConfig,
  };
  return encodeURIComponent(JSON.stringify(iframeParams));
}
