import { InitParams, TranslatedStrings, Subscription, Styles } from '../../types';
import {
  addNewCardDividerIfNotPresent,
  createDivider,
  createElement,
  getShortDateString,
  getPriceString,
  priceFromMinorUnits,
} from '../../utils';
import { getTranslatedStrings } from '../getTranslatedStrings';

export function initSubscription(
  initParams: InitParams,
  iframe: HTMLIFrameElement,
  payGrid: HTMLDivElement
): void {
  if (initParams.paymentType !== 'Recurring' || !initParams.subscription) {
    return;
  }

  const translatedStrings = getTranslatedStrings(initParams.localisationConfig.bcp47LanguageTag);
  const styles = initParams.styles;
  const subscriptionGrid = createSubscriptionGrid(
    initParams.subscription,
    translatedStrings,
    styles,
    initParams.localisationConfig.bcp47LanguageTag
  );
  const divider = createDivider(
    'ryft-pay-subscription-divider',
    translatedStrings['subscription'],
    styles
  );
  payGrid.append(divider);
  payGrid.append(subscriptionGrid);
  addNewCardDividerIfNotPresent(iframe, styles, translatedStrings);
}

function createSubscriptionGrid(
  subscription: Subscription,
  translatedStrings: TranslatedStrings,
  styles: Styles,
  bcp47LanguageTag: string
): HTMLDivElement {
  const subscriptionGrid = createElement('div', {
    id: 'ryft-pay-subscription-grid',
    class: 'ryft-pay-subscription-grid',
  });
  if (subscription.description) {
    const subscriptionDescription = createElement(
      'div',
      { id: 'ryft-pay-subscription-description', class: 'ryft-pay-subscription-description' },
      subscription.description
    );
    subscriptionDescription.style.color = styles.color;
    subscriptionGrid.append(subscriptionDescription);
  }
  const price = priceFromMinorUnits(subscription.price.currency, subscription.price.amount);
  const priceString = getPriceString(price, bcp47LanguageTag);

  const intervalUnit = subscription.price.interval.unit;
  const intervalCount = subscription.price.interval.count;
  const interval =
    intervalCount === 1
      ? intervalUnit === 'Days'
        ? translatedStrings['per-day']
        : translatedStrings['per-month']
      : `${translatedStrings['every']} ${intervalCount} ${intervalUnit.toLowerCase()}`;
  const priceIntervalString = `${priceString} ${interval}`;
  const startDateString = getShortDateString(
    subscription.billingDetail.billingCycleTimestamp,
    bcp47LanguageTag
  );
  const times = subscription.price.interval.times;
  const subscriptionCard = createSubscriptionCard(
    priceIntervalString,
    startDateString,
    translatedStrings,
    styles,
    times
  );
  subscriptionGrid.append(subscriptionCard);
  return subscriptionGrid;
}

function createSubscriptionCard(
  price: string,
  startDate: string,
  translatedStrings: TranslatedStrings,
  styles: Styles,
  times?: number
): HTMLDivElement {
  const subscriptionCard = createElement('div', { class: 'ryft-pay-subscription-card' }, '');

  subscriptionCard.style.borderColor = styles.borderColor;
  subscriptionCard.style.color = styles.color;
  subscriptionCard.style.backgroundColor = styles.backgroundColor;
  subscriptionCard.style.borderRadius = styles.borderRadius;

  const details = [
    { key: 'amount', value: price },
    { key: 'starts', value: startDate },
    { key: 'times', value: times?.toString() ?? '~' },
  ];

  details.forEach(detail => {
    const value = detail.value;
    const label = translatedStrings[detail.key];
    const labelElement = createElement(
      'div',
      { class: `ryft-pay-subscription-card-label ryft-pay-subscription-card-${detail.key}-label` },
      label
    );
    subscriptionCard.append(labelElement);
    const valueElement = createElement(
      'div',
      { class: `ryft-pay-subscription-card-value ryft-pay-subscription-card-${detail.key}` },
      value
    );
    subscriptionCard.append(valueElement);
  });

  return subscriptionCard;
}
