﻿import { AppConfig } from '../config';
import {
  ApplePaySessionResponse,
  ContinuePaymentRequest,
  CreateApplePaySessionRequest,
  InternalAttemptPaymentRequest,
  PaymentSession,
  RyftApiError,
} from '../types';
import { sendExceptionToSentry, sendErrorToSentry } from './sentryService';

export async function internalAttemptPayment(
  publicKey: string,
  request: InternalAttemptPaymentRequest,
  accountId?: string
): Promise<PaymentSession | RyftApiError> {
  return await callApi<PaymentSession>(
    '/v1/payment-sessions/attempt-payment',
    'POST',
    publicKey,
    accountId,
    request
  );
}

export async function continuePayment(
  publicKey: string,
  request: ContinuePaymentRequest,
  accountId?: string
): Promise<PaymentSession | RyftApiError> {
  return await callApi<PaymentSession>(
    '/v1/payment-sessions/continue-payment',
    'POST',
    publicKey,
    accountId,
    request
  );
}

export async function getPaymentSession(
  publicKey: string,
  clientSecret: string,
  accountId?: string
): Promise<PaymentSession | RyftApiError> {
  return await callApi<PaymentSession>(
    `/v1/payment-sessions/${clientSecret.split('_secret_')[0]}?clientSecret=${clientSecret}`,
    'GET',
    publicKey,
    accountId
  );
}

export async function createApplePaySession(
  publicKey: string,
  request: CreateApplePaySessionRequest,
  accountId?: string
): Promise<ApplePaySessionResponse | RyftApiError> {
  return await callApi<ApplePaySessionResponse>(
    '/v1/apple-pay/sessions',
    'POST',
    publicKey,
    accountId,
    request
  );
}

async function callApi<T>(
  urlSuffix: string,
  httpMethod: 'GET' | 'POST',
  publicKey: string,
  accountId?: string,
  requestBody?: object
): Promise<T | RyftApiError> {
  const url = getApiUrl(publicKey) + urlSuffix;
  try {
    const response = await fetch(url, {
      method: httpMethod,
      headers: defaultRequestHeaders(publicKey, accountId),
      body: requestBody ? JSON.stringify(requestBody) : undefined,
      redirect: 'follow',
    });
    const responseBody = await response.json();
    if (!response.ok) {
      const apiErrorMessage = extractApiErrorMessage(responseBody as RyftApiError);
      const message = `Error: '${apiErrorMessage}' (code: ${
        response.status
      }) from ${httpMethod} ${removeUniqueParams(urlSuffix)}`;
      sendErrorToSentry(message);
    }
    return responseBody;
  } catch (error) {
    sendExceptionToSentry(error);
    if (error instanceof Error) {
      console.error(`Error: ${error.message} from ${httpMethod} ${urlSuffix}`);
    } else {
      console.error(`Error: ${error} from ${httpMethod} ${urlSuffix}`);
    }
    return createUnknownApiError(
      'An unexpected error occurred, please try again or contact support.'
    );
  }
}

function extractApiErrorMessage(errorResponse?: RyftApiError): string {
  const rawMessage = errorResponse?.errors[0]?.message;
  // Remove payment session id from response so errors are grouped in Sentry
  return rawMessage?.replace(/ps_[A-Za-z0-9]+/g, '') || '';
}

function removeUniqueParams(urlSuffix: string): string {
  return urlSuffix.split('ps_')[0];
}

function defaultRequestHeaders(apiKey: string, accountId?: string): Headers {
  const headers = new Headers();
  headers.set('Authorization', apiKey);
  headers.set('Content-Type', 'application/json');
  headers.set('X-Ryft-Client', `ryft-embedded/${AppConfig.embeddedVersion}`);

  if (accountId) {
    headers.set('Account', accountId);
  }
  return headers;
}

function createUnknownApiError(message: string): RyftApiError {
  return {
    requestId: '',
    code: '500',
    errors: [
      {
        code: 'unexpected_error',
        message: message,
      },
    ],
  };
}

function getApiUrl(publicKey: string): string {
  if (publicKey.startsWith('pk_test')) {
    return 'https://test-api.ryftpay.com';
  } else if (publicKey.startsWith('pk_sandbox')) {
    return 'https://sandbox-api.ryftpay.com';
  } else {
    return 'https://api.ryftpay.com';
  }
}
