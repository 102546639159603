import { createApplePayPaymentSession } from './createApplePayPaymentSession';
import { getIsoLanguageCode } from '../getIsoLanguageCode';
import { ApplePayConfig, PaymentSession, PaymentType } from '../../types';

export function createApplePayButton(
  applePayConfig: ApplePayConfig,
  paymentType: PaymentType,
  bcp47LanguageTag: string,
  storeWalletDetails: boolean,
  paymentSession: PaymentSession,
  publicKey: string,
  clientSecret: string,
  accountId?: string
): HTMLButtonElement | undefined {
  const applePayButtonColor = applePayConfig.buttonConfiguration.color;
  const applePayButtonHeight = applePayConfig.buttonConfiguration.height;
  const applePayButtonType = applePayConfig.buttonConfiguration.type;

  const applePayButton = document.createElement('button');
  applePayButton.id = 'ryft-pay-apple-pay-button';
  applePayButton.type = 'button';
  applePayButton.lang = getIsoLanguageCode(bcp47LanguageTag);
  applePayButton.className = 'ryft-pay-apple-pay-button';
  applePayButton.style.height = `${applePayButtonHeight}px`;

  applePayButton.style.setProperty('-apple-pay-button-type', applePayButtonType);
  applePayButton.style.setProperty('-apple-pay-button-style', applePayButtonColor);
  applePayButton.style.setProperty('-webkit-appearance', '-apple-pay-button');

  applePayButton.onclick = (): void => {
    createApplePayPaymentSession(
      applePayConfig,
      publicKey,
      clientSecret,
      storeWalletDetails,
      paymentSession,
      accountId
    );
  };

  return applePayButton;
}
