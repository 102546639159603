import { PaymentMethod } from './PaymentMethod';
import { Subscription } from './Subscription';
import { Usage } from './Usage';
import { PaymentType } from './PaymentType';

export interface InitParams {
  publicKey: string;
  clientSecret?: string;
  accountId?: string;
  manuallyHandleActions: boolean;
  localisationConfig: LocalisationConfig;
  styles: Styles;
  applePayConfig?: ApplePayConfig;
  googlePayConfig?: GooglePayConfig;
  fieldCollectionConfig: FieldCollectionConfig;
  subscription?: Subscription;
  usage: Usage;
  paymentType: PaymentType;
  allowPaymentMethodStorage: boolean;
  customerPaymentMethods: PaymentMethod[];
}

export interface LocalisationConfig {
  bcp47LanguageTag: string;
  cardNumberPlaceholder?: string;
  expiryMonthPlaceholder?: string;
  expiryYearPlaceholder?: string;
  cvvPlaceholder?: string;
  nameOnCardPlaceholder?: string;
}

export type TranslatedStrings = { [key: string]: string };

export interface Styles {
  borderRadius: string;
  backgroundColor: string;
  borderColor: string;
  padding: string;
  color: string;
  focusColor: string;
  bodyColor: string;
  dividerColor: string;
  dividerLineColor: string;
}

export interface ApplePayConfig {
  merchantName: string;
  merchantCountryCode: string;
  buttonConfiguration: {
    color: string;
    height: number;
    type: string;
  };
}

export interface GooglePayConfig {
  merchantIdentifier?: string;
  merchantName: string;
  merchantCountryCode: string;
  buttonConfiguration: {
    height: number;
    color: google.payments.api.ButtonColor;
    type: google.payments.api.ButtonType;
  };
}

export interface FieldCollectionConfig {
  billingAddressConfig: BillingAddressConfig;
  collectNameOnCard: boolean;
}

export interface BillingAddressConfig {
  display: BillingAddressDisplay;
  value?: BillingAddressValue;
}

export const ValidBillingAddressDisplays: string[] = ['full', 'minimum', 'hidden'];
export type BillingAddressDisplay = 'full' | 'minimum' | 'hidden';

export interface BillingAddressValue {
  firstName?: string;
  lastName?: string;
  lineOne?: string;
  lineTwo?: string;
  city?: string;
  region?: string;
  postalCode?: string;
  country?: string;
}
