﻿export function waitForEventWithTimeout<T>(
  emitter: Window,
  eventName: string,
  timeout: number,
  isEventPredicate: (event: MessageEvent) => boolean,
  eventTransformer?: (event: MessageEvent) => T
): Promise<T> {
  return new Promise((resolve, reject) => {
    const listener: EventListener = (event: Event) => {
      if (event instanceof MessageEvent && isEventPredicate && !isEventPredicate(event)) {
        return;
      }
      clearTimeout(timer);
      emitter.removeEventListener(eventName, listener);
      if (eventTransformer && event instanceof MessageEvent) {
        resolve(eventTransformer(event));
      } else {
        reject(
          new Error(
            'Event transformer is required but not provided or event is not a MessageEvent.'
          )
        );
      }
    };

    emitter.addEventListener(eventName, listener, false);
    const timer = window.setTimeout(() => {
      emitter.removeEventListener(eventName, listener);
      reject(new Error(`timeout waiting for ${eventName}`));
    }, timeout);
  });
}
