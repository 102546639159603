﻿import { RyftApiErrorElement } from '../../types';

export function createApplePayError(
  errorElement: RyftApiErrorElement
): ApplePayError | ApplePayError[] {
  if (
    !errorElement.message.startsWith('billingAddress') &&
    !errorElement.message.startsWith('customerDetails')
  ) {
    return [];
  }
  const parts = errorElement.message.split('.');
  if (parts.length < 2) {
    return [];
  }
  const field = parts[1].split(' ')[0];
  switch (field) {
    case 'email':
      return new ApplePayError('shippingContactInvalid', 'emailAddress', errorElement.message);
    case 'firstName':
    case 'lastName':
      return new ApplePayError('billingContactInvalid', 'name', errorElement.message);
    case 'lineOne':
    case 'lineTwo':
      return new ApplePayError('billingContactInvalid', 'addressLines', errorElement.message);
    case 'city':
      return new ApplePayError('billingContactInvalid', 'locality', errorElement.message);
    case 'region':
      return new ApplePayError('billingContactInvalid', 'administrativeArea', errorElement.message);
    case 'postalCode':
      return new ApplePayError('billingContactInvalid', 'postalCode', errorElement.message);
    case 'country':
      return new ApplePayError('billingContactInvalid', 'countryCode', errorElement.message);
    default:
      return [];
  }
}
