﻿export interface ValidationResult {
  valid: boolean;
  error?: string;
}

export function validationFail(error: string): ValidationResult {
  return {
    valid: false,
    error: error,
  };
}

export function validationSuccess(): ValidationResult {
  return {
    valid: true,
    error: undefined,
  };
}
