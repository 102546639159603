import { Address, BillingAddressConfig } from '../types';

export function getBillingAddressForPayment(
  billingAddressConfig: BillingAddressConfig,
  enteredBillingAddress?: Address
): Address | undefined {
  const display = billingAddressConfig.display;
  const initValue = billingAddressConfig.value;
  switch (display) {
    case 'full':
      return enteredBillingAddress;
    case 'minimum':
      return enteredBillingAddress
        ? {
            firstName: initValue?.firstName,
            lastName: initValue?.lastName,
            region: enteredBillingAddress.region,
            postalCode: enteredBillingAddress.postalCode,
            country: enteredBillingAddress.country,
          }
        : undefined;
    default:
      return initValue && initValue.country && initValue.postalCode
        ? {
            firstName: initValue.firstName,
            lastName: initValue.lastName,
            lineOne: initValue.lineOne,
            lineTwo: initValue.lineTwo,
            city: initValue.city,
            region: initValue.region,
            postalCode: initValue.postalCode,
            country: initValue.country,
          }
        : undefined;
  }
}
