import { PaymentSessionLastError } from '../types';
import { getInitParams } from '../state';
import { sendErrorToSentry } from '../service';
import { getTranslatedStrings } from './getTranslatedStrings';

export const getUserFacingErrorMessage = (
  lastError: PaymentSessionLastError
): string | undefined => {
  const initParams = getInitParams();
  if (!initParams) {
    const message = 'You must call Ryft.init() prior to calling Ryft.getUserFacingErrorMessage()';
    console.error(message);
    sendErrorToSentry(message);
    return undefined;
  }
  const lang = initParams.localisationConfig.bcp47LanguageTag;
  const translatedStrings = getTranslatedStrings(lang);
  return (
    translatedStrings[lastError] ??
    'An unexpected error occurred, please try again or contact support.'
  );
};
