﻿import {
  BrowserClient,
  defaultStackParser,
  Event,
  getDefaultIntegrations,
  makeFetchTransport,
  Scope,
  SeverityLevel,
} from '@sentry/browser';
import { AppConfig } from '../config';

let scope: Scope | undefined;

export async function initSentry(
  publicKey: string,
  clientSecret?: string,
  accountId?: string
): Promise<void> {
  // filter integrations that use the global variable - recommended by Sentry when used via SDK (multiple instances)
  const integrations = getDefaultIntegrations({}).filter(defaultIntegration => {
    return !['BrowserApiErrors', 'Breadcrumbs', 'GlobalHandlers'].includes(defaultIntegration.name);
  });
  const client = new BrowserClient({
    dsn: AppConfig.sentryDsn,
    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    integrations: integrations,
    sampleRate: 1,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  });
  scope = new Scope();
  scope.setClient(client);
  // Not calling client.init() as it's not strictly required and causes issues in some scenarios
  // if clients have their own Sentry setup - init() can't be called multiple times
  await configureSentry(publicKey, clientSecret, accountId);
}

export async function configureSentry(
  publicKey: string,
  clientSecret?: string,
  accountId?: string
): Promise<void> {
  if (!scope) {
    console.log('scope is not set');
    return;
  }
  scope.addEventProcessor(function (event: Event): Event {
    if (!event.request) {
      event.request = {};
    }
    event.request.url = window.location.href;
    if (!event.request.headers) {
      event.request.headers = {};
    }
    event.request.headers['User-Agent'] = window.navigator.userAgent;
    event.environment = getSentryEnvironment(publicKey);
    return event;
  });
  scope.setUser({ id: accountId || publicKey });
  if (clientSecret) {
    scope.setTransactionName(clientSecret.split('_secret_')[0]);
  }
}

export function sendExceptionToSentry(error: unknown): void {
  scope?.captureException(error);
}

export function sendErrorToSentry(message: string): void {
  sendMessageToSentry(message, 'error');
}

export function sendWarningToSentry(message: string): void {
  sendMessageToSentry(message, 'warning');
}

function sendMessageToSentry(message: string, level: SeverityLevel): void {
  scope?.captureMessage(message, level);
}

function getSentryEnvironment(publicKey: string): string {
  if (publicKey.startsWith('pk_test')) {
    return 'test';
  } else if (publicKey.startsWith('pk_sandbox')) {
    return 'sandbox';
  } else {
    return 'prod';
  }
}
