import { createElement } from '../domGenerators';
import { renderThreeDsChallenge } from './renderThreeDsChallenge';
import { IdentifyAction } from '../../types';
import { removeThreeDsModal } from './removeThreeDsModal';
import { images } from '../injectStyles';

export function displayThreeDsModal(
  cardScheme?: IdentifyAction['scheme'],
  acsUrl?: string,
  cReq?: string
): void {
  removeThreeDsModal();
  const modalAnchor = createElement('div', { id: 'ryft-modal-anchor' });
  modalAnchor.classList.add('ryft-modal-anchor');

  const modalBackground = createElement('div', { id: 'ryft-modal-background' });
  modalBackground.classList.add('ryft-modal-background');
  modalAnchor.appendChild(modalBackground);

  const modalWindow = createElement('div', { id: 'ryft-modal-window' });
  modalWindow.classList.add('ryft-modal-window');
  modalBackground.appendChild(modalWindow);

  const loadingDiv = createElement('div', { id: 'ryft-modal-loading' });
  loadingDiv.classList.add('ryft-modal-loading');
  modalWindow.appendChild(loadingDiv);

  const spinnerImage = createElement('img', {
    src: images.Spinner,
    alt: 'Loading spinner',
  });
  spinnerImage.classList.add('ryft-loading-spinner');
  loadingDiv.appendChild(spinnerImage);

  if (cardScheme && images[cardScheme]) {
    const cardImage = createElement('img', {
      src: images[cardScheme],
      alt: `${cardScheme} verification image`,
    });
    loadingDiv.appendChild(cardImage);
  }

  const firstChild = document.body.firstElementChild;
  document.body.insertBefore(modalAnchor, firstChild);

  if (acsUrl && cReq) {
    renderThreeDsChallenge(acsUrl, cReq);
  }
}
