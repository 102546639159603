﻿import { PaymentMethod, PaymentSession } from '../common';

export type Events = {
  walletPaymentSessionResult: WalletPaymentSessionResultEventData;
  cardValidationChanged: CardValidationChangedEventData;
  paymentMethodSelectionChanged: PaymentMethodSelectionChangedEventData;
  billingAddressValidationChanged: BillingAddressValidationChangedEventData;
};

export const walletPaymentRequiredActionEventName = 'walletPaymentRequiredAction';

export type BillingAddressValidationChangedEventData = {
  eventName: 'billingAddressValidationChanged';
  isValid: boolean;
};

export type CardValidationChangedEventData = {
  eventName: 'cardValidationChanged';
  isValid: boolean;
  cardNumberValid: boolean;
  expirationValid: boolean;
  cvcValid: boolean;
  nameOnCardValid: boolean;
};

export type PaymentMethodSelectionChangedEventData = {
  eventName: 'paymentMethodSelectionChanged';
  paymentMethod?: PaymentMethod;
  validation: {
    expirationValid: boolean;
  };
};

export type WalletPaymentSessionResultEventData = {
  eventName: 'walletPaymentSessionResult';
  paymentSession: PaymentSession;
};

export interface WalletPaymentRequiredActionEventData {
  detail: {
    paymentSession: PaymentSession;
  };
}

export interface ErrorEventData {
  detail: {
    error: string;
  };
}
