import { getIsoLanguageCode } from '../getIsoLanguageCode';
import { GooglePayConfig, Styles } from '../../types';
import { sendErrorToSentry } from '../../service';

export function createGooglePayButton(
  googlePayData: GooglePayConfig,
  paymentsClient: google.payments.api.PaymentsClient,
  paymentDataRequest: google.payments.api.PaymentDataRequest,
  styles: Styles,
  bcp47LanguageTag: string
): HTMLButtonElement {
  const googlePayButtonHeight = googlePayData.buttonConfiguration.height;
  const googlePayButtonType = googlePayData.buttonConfiguration.type;
  const googlePayButtonColor = googlePayData.buttonConfiguration.color;
  const googlePayButtonRadius = +styles.borderRadius.replace('px', '');
  const buttonOptions: google.payments.api.ButtonOptions = {
    onClick: async () => {
      try {
        return await paymentsClient.loadPaymentData(paymentDataRequest);
      } catch (error: unknown) {
        const message = error instanceof Error ? error.message : String(error);
        if (message !== 'User closed the Payment Request UI.') {
          console.error(error);
          sendErrorToSentry(`loadPaymentData failed with error: ${message}`);
        }
      }
    },
    buttonRadius: googlePayButtonRadius,
    buttonType: googlePayButtonType,
    buttonColor: googlePayButtonColor,
    buttonLocale: getIsoLanguageCode(bcp47LanguageTag),
    buttonSizeMode: 'fill',
  };
  const googlePayButton = paymentsClient.createButton(buttonOptions);
  googlePayButton.id = 'ryft-pay-google-pay-button';
  googlePayButton.style.height = `${googlePayButtonHeight}px`;
  googlePayButton.classList.add('ryft-pay-google-pay-button');
  return <HTMLButtonElement>googlePayButton;
}
