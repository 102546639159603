﻿import { EventHandlers, Events } from '../types';

let eventHandlers: EventHandlers = {};

export const internalAddEventHandler = <K extends keyof Events>(
  eventName: K,
  handler: EventHandlers[K]
): void => {
  eventHandlers[eventName] = handler;
};

export const getEventHandlers = (): EventHandlers => eventHandlers;

export const resetEventHandlers = (): void => {
  eventHandlers = {};
};
