import { InitParams } from '../types';

export function calculateIframeHeight(initParams: InitParams): number {
  let height =
    initParams.usage === 'setupCard'
      ? 100
      : initParams.allowPaymentMethodStorage &&
          !initParams.subscription &&
          initParams.paymentType === 'Standard'
        ? 90
        : initParams.fieldCollectionConfig.billingAddressConfig.display !== 'hidden'
          ? 48
          : 55;
  if (initParams.fieldCollectionConfig.collectNameOnCard) {
    height += 60;
  }
  return height;
}
