﻿import { initApplePay } from './applePay';
import { initGooglePay } from './googlePay';
import { InitParams, PaymentSession, RyftApiError } from '../../types';
import { getPaymentSession } from '../../service';
import { addNewCardDividerIfNotPresent } from '../../utils';
import { getTranslatedStrings } from '../getTranslatedStrings';

export async function initPaymentButtons(
  initParams: InitParams,
  iframe: HTMLIFrameElement,
  payGrid: HTMLDivElement
): Promise<void> {
  if (!initParams.googlePayConfig && !initParams.applePayConfig) {
    return;
  }
  if (!initParams.clientSecret) {
    console.warn('Ryft.init() No client secret provided, cannot offer google or apple pay');
    return;
  }
  const translatedStrings = getTranslatedStrings(initParams.localisationConfig.bcp47LanguageTag);

  const apiResponse = await getPaymentSession(
    initParams.publicKey,
    initParams.clientSecret,
    initParams.accountId
  );
  const paymentSession = apiResponse as PaymentSession;
  const apiError = apiResponse as RyftApiError;
  if (apiError.code) {
    console.warn('Ryft.init() Failed to fetch payment session, cannot offer google or apple pay');
    return;
  }

  const paymentButtonsDiv = document.createElement('div');
  paymentButtonsDiv.classList.add('payment-buttons-container');

  const applePayButton = await initApplePay(initParams, paymentSession);
  if (applePayButton) {
    addWalletButtonToPayGrid(payGrid, applePayButton);
    addNewCardDividerIfNotPresent(iframe, initParams.styles, translatedStrings);
    return;
  }
  const googlePayButton = await initGooglePay(initParams, iframe, paymentSession);
  if (googlePayButton) {
    addWalletButtonToPayGrid(payGrid, googlePayButton);
    addNewCardDividerIfNotPresent(iframe, initParams.styles, translatedStrings);
    return;
  }
}

function addWalletButtonToPayGrid(payGrid: HTMLDivElement, walletButton: HTMLButtonElement): void {
  const subscriptionGrid = document.getElementById('ryft-pay-subscription-grid');
  if (subscriptionGrid) {
    subscriptionGrid.classList.add('ryft-pay-subscription-grid-wallet-padding');
    payGrid.insertBefore(walletButton, subscriptionGrid.nextSibling);
    return;
  }
  payGrid.prepend(walletButton);
}
