﻿import { ThreeDsRequestDetails } from '../types';

export function getThreeDsRequestDetails(): ThreeDsRequestDetails {
  return {
    deviceChannel: 'Browser',
    browserDetails: {
      acceptHeader: '*/*',
      colorDepth: window.screen.colorDepth,
      javaEnabled: false,
      language: window.navigator.language,
      screenHeight: window.screen.height,
      screenWidth: window.screen.width,
      timeZoneOffset: new Date().getTimezoneOffset(),
      userAgent: window.navigator.userAgent,
    },
  };
}
