const formName: string = 'ryft-pay-form';
// Required for clients using ASP.NET as it doesn't allow them to create ids with hyphens in
const fallbackFormName: string = 'ryftPayForm';

export function findForm(retryCount = 0): HTMLFormElement {
  let form = document.getElementById(formName) as HTMLFormElement | null;
  if (form) {
    return form;
  }
  form = document.getElementById(fallbackFormName) as HTMLFormElement | null;
  if (form) {
    return form;
  }
  if (retryCount < 3) {
    console.warn(
      `Form with id: ${formName} or ${fallbackFormName} not found, retrying in ${100 * (retryCount + 1)}ms...`
    );
    setTimeout(
      () => {
        return findForm(retryCount + 1);
      },
      100 * (retryCount + 1)
    );
  }
  throw new Error(`Form with id: ${formName} or ${fallbackFormName} not found after 3 retries`);
}
