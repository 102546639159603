import { Price } from '../types';

export function priceFromMinorUnits(currencyCode: string, amount: number): Price {
  const minorDigitsMap: { [key: string]: number } = {
    BHD: 3,
    IQD: 3,
    JOD: 3,
    KWD: 3,
    LYD: 3,
    OMR: 3,
    TND: 3,
    BIF: 0,
    CLP: 0,
    DJF: 0,
    GNF: 0,
    ISK: 0,
    JPY: 0,
    KMF: 0,
    KRW: 0,
    PYG: 0,
    RWF: 0,
    UGX: 0,
    UYI: 0,
    VND: 0,
    VUV: 0,
    XPF: 0,
    XOF: 0,
    XAF: 0,
    CLF: 4,
    UYW: 4,
  };
  const minorDigits = minorDigitsMap[currencyCode] || 2;
  const majorDigits = amount / Math.pow(10, minorDigits);
  return { majorDigits, currencyCode };
}
