import stringsEn from '../i18n/strings_en.json';
import stringsEs from '../i18n/strings_es.json';
import { TranslatedStrings } from '../types';
import { getIsoLanguageCode } from '../utils';

export const getTranslatedStrings = (bcp47LanguageTag: string): TranslatedStrings => {
  const lang = getIsoLanguageCode(bcp47LanguageTag);
  const translatedStrings: TranslatedStrings =
    lang === 'es' || lang == 'es-es' ? stringsEs : stringsEn;
  return translatedStrings;
};
