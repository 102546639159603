import { createDivider } from './createDivider';
import { TranslatedStrings, Styles, NewCardDividerId } from '../../types';

export function addNewCardDividerIfNotPresent(
  iframe: HTMLIFrameElement,
  styles: Styles,
  translatedStrings: TranslatedStrings
): void {
  const id = NewCardDividerId;
  const existingDivider = document.getElementById(id);
  if (existingDivider) {
    return;
  }
  const divider = createDivider(id, translatedStrings['enter-a-new-card'], styles);
  iframe?.parentNode?.insertBefore(divider, iframe);
}
