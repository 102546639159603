﻿import {
  Address,
  ErrorEventData,
  Events,
  IFrameAttemptPaymentEvent,
  IFrameEvent,
  IFrameGooglePayReadyEvent,
  PaymentMethod,
  PaymentSession,
  WalletPaymentRequiredActionEventData,
  walletPaymentRequiredActionEventName,
} from '../types';
import { getEventHandlers, getIFrame } from '../state';
import { sendErrorToSentry } from './sentryService';

export function sendBillingAddressValidationChangedEvent(isAddressValid: boolean): void {
  const eventName: keyof Events = 'billingAddressValidationChanged';
  sendEventToClient(eventName, { eventName, isValid: isAddressValid });
}

export function sendPaymentMethodSelectionChangedEvent(
  cardExpirationValid: boolean,
  paymentMethod?: PaymentMethod
): void {
  const eventName: keyof Events = 'paymentMethodSelectionChanged';
  sendEventToClient(eventName, {
    eventName,
    paymentMethod,
    validation: { expirationValid: cardExpirationValid },
  });
}

export function sendWalletPaymentSessionResultEvent(paymentSession: PaymentSession): void {
  const eventName: keyof Events = 'walletPaymentSessionResult';
  sendEventToClient(eventName, { eventName, paymentSession });
}

export function sendAttemptPaymentEvent(
  publicKey: string,
  clientSecret: string,
  transfer: Transferable[],
  accountId?: string,
  customerEmail?: string,
  billingAddress?: Address
): void {
  const event: IFrameAttemptPaymentEvent = {
    eventName: 'attemptPayment',
    publicKey,
    clientSecret,
    accountId,
    customerEmail,
    billingAddress,
  };
  sendEventToIFrame(event, transfer);
}

export function sendGooglePayReadyEvent(paymentSession: PaymentSession): void {
  const event: IFrameGooglePayReadyEvent = {
    eventName: 'googlePayReady',
    paymentSession,
  };
  sendEventToIFrame(event);
}

export function sendWalletPaymentRequiredActionEvent(paymentSession: PaymentSession): void {
  const eventData: WalletPaymentRequiredActionEventData = {
    detail: {
      paymentSession,
    },
  };
  window.dispatchEvent(new CustomEvent(walletPaymentRequiredActionEventName, eventData));
}

export function sendErrorEvent(message: string): void {
  const eventData: ErrorEventData = {
    detail: {
      error: message,
    },
  };
  window.dispatchEvent(new CustomEvent('error', eventData));
}

export const sendEventToClient = <K extends keyof Events>(
  eventName: K,
  eventData: Events[K]
): void => {
  const handler = getEventHandlers()[eventName];
  if (handler) {
    handler(eventData);
  }
};

function sendEventToIFrame(message: IFrameEvent, transfer?: Transferable[]): void {
  const iframe = getIFrame();
  if (iframe && iframe.contentWindow) {
    iframe.contentWindow.postMessage(message, '*', transfer);
    return;
  }
  const error = `Error sending ${message.eventName} event to iframe as iframe: ${iframe} or iframe.contentWindow was not present`;
  sendErrorToSentry(error);
  console.error(error);
}
