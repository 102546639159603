﻿import { priceFromMinorUnits } from '../priceFromMinorUnits';
import { onValidateMerchant } from './onValidateMerchant';
import { onApplePayPaymentAuthorized } from './onApplePayPaymentAuthorized';
import { ApplePayConfig, PaymentSession } from '../../types';

export function createApplePayPaymentSession(
  applePayConfig: ApplePayConfig,
  publicKey: string,
  clientSecret: string,
  storeWalletDetails: boolean,
  paymentSession: PaymentSession,
  accountId?: string
): void {
  const price = priceFromMinorUnits(paymentSession.currency, paymentSession.amount);
  const paymentRequest: ApplePayJS.ApplePayPaymentRequest = {
    countryCode: applePayConfig.merchantCountryCode,
    currencyCode: paymentSession.currency,
    merchantCapabilities: ['supports3DS'],
    supportedNetworks: ['visa', 'masterCard', 'amex'],
    total: {
      label: applePayConfig.merchantName,
      amount: `${price.majorDigits}`,
      type: 'final',
    },
    requiredBillingContactFields: ['postalAddress'],
    requiredShippingContactFields: paymentSession.customerEmail === undefined ? ['email'] : [],
  };
  const applePaySession = new ApplePaySession(3, paymentRequest);
  applePaySession.begin();
  applePaySession.onvalidatemerchant = (): void => {
    onValidateMerchant(publicKey, applePayConfig.merchantName, applePaySession, accountId);
  };
  applePaySession.onpaymentauthorized = (
    applePayPaymentAuthorizedEvent: ApplePayJS.ApplePayPaymentAuthorizedEvent
  ): void => {
    onApplePayPaymentAuthorized(
      publicKey,
      clientSecret,
      applePayPaymentAuthorizedEvent,
      applePaySession,
      storeWalletDetails,
      accountId
    );
  };
}
