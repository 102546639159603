import { GooglePayConfig, PaymentSession, Price } from '../../types';
import {
  ALLOWED_AUTH_METHODS,
  ALLOWED_CARD_NETWORKS,
  API_MINOR_VERSION,
  API_MAJOR_VERSION,
  getBillingAddressParameters,
  PAYMENT_METHOD_CARD,
} from './googlePayConstants';
import { priceFromMinorUnits } from '../priceFromMinorUnits';

export function createPaymentDataRequest(
  billingAddressRequired: boolean,
  publicKey: string,
  googlePayConfig: GooglePayConfig,
  paymentSession: PaymentSession
): google.payments.api.PaymentDataRequest {
  const price: Price = priceFromMinorUnits(paymentSession.currency, paymentSession.amount);
  return {
    apiVersion: API_MAJOR_VERSION,
    apiVersionMinor: API_MINOR_VERSION,
    allowedPaymentMethods: [
      {
        type: PAYMENT_METHOD_CARD,
        parameters: {
          allowedAuthMethods: ALLOWED_AUTH_METHODS,
          allowedCardNetworks: ALLOWED_CARD_NETWORKS,
          billingAddressRequired,
          billingAddressParameters: getBillingAddressParameters(billingAddressRequired),
        },
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            gatewayMerchantId: publicKey,
            gateway: 'ryft',
          },
        },
      },
    ],
    transactionInfo: {
      transactionId: paymentSession.id,
      checkoutOption: 'COMPLETE_IMMEDIATE_PURCHASE',
      totalPriceStatus: 'FINAL',
      totalPrice: `${price.majorDigits}`,
      currencyCode: price.currencyCode,
      countryCode: googlePayConfig.merchantCountryCode,
    },
    merchantInfo: {
      merchantId: googlePayConfig.merchantIdentifier!,
      merchantName: googlePayConfig.merchantName,
    },
    callbackIntents: ['PAYMENT_AUTHORIZATION'],
    emailRequired: paymentSession.customerEmail !== undefined,
  };
}
