import { InitParams, PaymentSession, RequiredAction, RyftApiError } from '../types';
import { getInitParams } from '../state';
import { handleThreeDsRequiredAction } from '../utils';
import { configureSentry, sendErrorToSentry } from '../service';
import { validateAccountId, validateClientSecret, validatePublicKey } from '../validators';

export const handleRequiredAction = async (
  paymentSessionOrRequiredAction: PaymentSession | RequiredAction,
  publicKey?: string,
  clientSecret?: string,
  accountId?: string
): Promise<PaymentSession | undefined> => {
  let initParams = getInitParams();
  validateRequest(initParams, publicKey, clientSecret, accountId);

  let validPublicKey;
  let validClientSecret;
  let validAccountId;
  if (publicKey) {
    validPublicKey = publicKey;
    validClientSecret = clientSecret!;
    validAccountId = accountId;
    // new values have been provided, update Sentry
    await configureSentry(validPublicKey, validClientSecret, validAccountId);
  } else {
    validPublicKey = initParams!.publicKey;
    validClientSecret = initParams!.clientSecret!;
    validAccountId = initParams!.accountId;
  }

  const paymentSession = paymentSessionOrRequiredAction as PaymentSession;
  let requiredAction = paymentSessionOrRequiredAction as RequiredAction;

  if (paymentSession.status) {
    if (paymentSession.status !== 'PendingAction' || !paymentSession.requiredAction) {
      return paymentSession;
    }
    requiredAction = paymentSession.requiredAction;
  }
  if (requiredAction.type) {
    const apiResponse = await handleThreeDsRequiredAction(
      requiredAction,
      false,
      validPublicKey,
      validClientSecret,
      validAccountId
    );
    if (apiResponse) {
      const updatedPaymentSession = apiResponse as PaymentSession;
      const apiErrorResponse = apiResponse as RyftApiError;
      if (apiErrorResponse.code) {
        throw new Error(apiErrorResponse.errors?.[0].message);
      }
      return updatedPaymentSession;
    }
  } else {
    const message = `Invalid input - must either be a PaymentSession or a RequiredAction`;
    sendErrorToSentry(message);
    console.error(message);
    throw new Error(message);
  }
};

function validateRequest(
  initParams?: InitParams,
  publicKey?: string,
  clientSecret?: string,
  accountId?: string
): void {
  if (!initParams && (!publicKey || !clientSecret)) {
    const message = `You must either call Ryft.init() prior to calling Ryft.handleRequiredAction() or provide a publicKey and clientSecret`;
    sendErrorToSentry(message);
    console.error(message);
    throw new Error(message);
  }
  if (publicKey && !validatePublicKey(publicKey)) {
    const message = `Ryft.handleRequiredAction() publicKey: ${publicKey} is invalid`;
    sendErrorToSentry(message);
    console.error(message);
    throw new Error(message);
  }
  if (clientSecret && !validateClientSecret(clientSecret)) {
    const message = `Ryft.handleRequiredAction() clientSecret: ${clientSecret} is invalid`;
    sendErrorToSentry(message);
    console.error(message);
    throw new Error(message);
  }
  if (publicKey && !clientSecret) {
    const message = `Ryft.handleRequiredAction() clientSecret must be provided if you provide a publicKey`;
    sendErrorToSentry(message);
    console.error(message);
    throw new Error(message);
  }
  if (clientSecret && !publicKey) {
    const message = `Ryft.handleRequiredAction() publicKey must be provided if you provide a clientSecret`;
    sendErrorToSentry(message);
    console.error(message);
    throw new Error(message);
  }
  if (accountId && !validateAccountId(accountId)) {
    const message = `Ryft.handleRequiredAction() accountId: ${accountId} is invalid`;
    sendErrorToSentry(message);
    console.error(message);
    throw new Error(message);
  }
}
