﻿import { handleThreeDsRedirectAction } from './handleThreeDsRedirectAction';
import { handleThreeDsChallengeAction } from './handleThreeDsChallengeAction';
import { handleThreeDsIdentifyAction } from './handleThreeDsIdentifyAction';
import { PaymentSession, RequiredAction, RyftApiError } from '../../types';
import { sendErrorToSentry } from '../../service';

export const handleThreeDsRequiredAction = async (
  action: RequiredAction,
  afterThreeDsMethod: boolean,
  publicKey: string,
  clientSecret: string,
  accountId?: string
): Promise<PaymentSession | RyftApiError | undefined> => {
  switch (action.type) {
    case 'Redirect':
      if (action.url) {
        handleThreeDsRedirectAction(action.url);
      } else {
        const message = 'RequiredAction was Redirect but no url was present';
        sendErrorToSentry(message);
        console.error(message);
      }
      break;
    case 'Challenge':
      if (action.challenge) {
        return handleThreeDsChallengeAction(
          action.challenge,
          afterThreeDsMethod,
          publicKey,
          clientSecret,
          accountId
        );
      } else {
        const message = 'RequiredAction was Challenge but no challenge object was present';
        sendErrorToSentry(message);
        console.error(message);
      }
      break;
    case 'Identify':
      if (action.identify) {
        return handleThreeDsIdentifyAction(action.identify, publicKey, clientSecret, accountId);
      } else {
        const message = 'RequiredAction was Identify but no identify object was present';
        sendErrorToSentry(message);
        console.error(message);
      }
      break;
    default: {
      const message = `RequiredAction has unsupported type: ${action.type}`;
      sendErrorToSentry(message);
      console.error(message);
    }
  }
  return undefined;
};
