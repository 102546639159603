import { InitParams } from '../types';

let initParams: InitParams | undefined = undefined;

export const getInitParams = (): InitParams | undefined => initParams;

export const setInitParams = (newData: InitParams): void => {
  initParams = newData;
};

export const updateInitParams = (newData: Partial<InitParams>): void => {
  if (initParams) {
    initParams = { ...initParams, ...newData };
  }
};
