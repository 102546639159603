export function createElement<K extends keyof HTMLElementTagNameMap>(
  tagName: K,
  attributes: Record<string, string> = {},
  text: string | null = null
): HTMLElementTagNameMap[K] {
  const element = document.createElement(tagName);
  for (const key of Object.keys(attributes)) {
    element.setAttribute(key, attributes[key]);
  }
  if (text !== null) {
    element.textContent = text;
  }
  return element;
}
