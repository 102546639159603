import { Address } from '../types';

export function getEnteredBillingAddress(): Address | undefined {
  const firstName =
    (document.getElementById('ryft-billingAddress-firstName') as HTMLInputElement)?.value ||
    undefined;
  const lastName =
    (document.getElementById('ryft-billingAddress-lastName') as HTMLInputElement)?.value ||
    undefined;
  const lineOne =
    (document.getElementById('ryft-billingAddress-addressLineOne') as HTMLInputElement)?.value ||
    undefined;
  const lineTwo =
    (document.getElementById('ryft-billingAddress-addressLineTwo') as HTMLInputElement)?.value ||
    undefined;
  const country =
    (document.getElementById('ryft-billingAddress-country-select') as HTMLSelectElement)?.value ||
    undefined;
  const postalCode =
    (document.getElementById('ryft-billingAddress-postalZipCode') as HTMLInputElement)?.value ||
    undefined;
  const city =
    (document.getElementById('ryft-billingAddress-addressCity') as HTMLInputElement)?.value ||
    undefined;
  const state =
    (document.getElementById('ryft-billingAddress-addressState-select') as HTMLSelectElement)
      ?.value || undefined;
  const region = state
    ? state
    : (document.getElementById('ryft-billingAddress-addressRegion') as HTMLInputElement)?.value ||
      undefined;

  if (country && postalCode) {
    return {
      firstName,
      lastName,
      lineOne,
      lineTwo,
      city,
      region,
      postalCode,
      country,
    };
  }

  return undefined;
}
