import { AppConfig } from '../config';

export function injectStyles(): void {
  const styles = document.createElement('style');
  styles.innerHTML = css;
  document.head.appendChild(styles);
}

const { baseUrl, embeddedVersion } = AppConfig;
const imagesBasePath = `${baseUrl}/${embeddedVersion}/images`;

export const images = {
  Visa: `${imagesBasePath}/visa_secure.svg`,
  Mastercard: `${imagesBasePath}/mastercard_id_check.svg`,
  Amex: `${imagesBasePath}/amex_safekey.svg`,
  Discover: `${imagesBasePath}/discover_protectbuy.svg`,
  Spinner: `${imagesBasePath}/spinner.svg`,
};

export function preloadImages(): void {
  Object.values(images).forEach(imageUrl => {
    const img = new Image();
    img.src = imageUrl;
  });
}

const css: string = `
@import"https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400;0,500;0,600;0,700;1,300&display=swap";body {
    background-color: rgba(0,0,0,0);
    font-family: "Inter",sans-serif
}

input {
    font-family: "Inter",sans-serif
}

select {
    font-family: "Inter",sans-serif
}

.ryft-iframe-style {
    border: 0;
    width: 0;
    height: 0
}

.ryft-form-style {
    display: none
}

#ryft-pay-iframe {
    width: 100%;
    border: none;
    overflow: hidden !important
}

#ryft-pay-apple-pay-button {
    width: 100%;
    cursor: pointer;
    display: inline-block
}

.ryft-pay-google-pay-button {
    width: 100%
}

.ryft-pay-grid {
    display: grid
}

.ryft-form-group {
    display: grid;
    grid-template-columns: 1fr;
    margin: 12px 0;
    grid-template-rows: auto auto 0
}

.ryft-form-group label {
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    margin-bottom: 2px;
    height: 15px
}

.ryft-form-group input {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid;
    line-height: 15px;
}

.ryft-form-group input:focus {
    outline: none
}

.ryft-form-group select {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    z-index: 1;
    display: block;
    line-height: 15px;
}

.down-button {
    text-align: right;
    position: relative;
    top: -30px;
    z-index: 999;
    padding-right: 10px;
    pointer-events: none
}

.ryft-form-group-half {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    margin: 0;
    height: 73px
}

.ryft-label-group {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 12px;
    margin: 0
}

.ryft-optional {
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    font-style: italic
}

.ryft-name-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    margin: 0
}

.ryft-name-inputs .ryft-form-group {
    margin: 0
}

@media screen and (max-width: 390px) {
    .ryft-name-inputs {
        grid-template-columns:1fr
    }
}

.ryft-label-div {
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    margin-bottom: 2px;
    height: 15px;
    color: #5a5a5a
}

.divider {
    width: 100%;
    text-align: center;
    height: 21px;
    margin-bottom: 20px;
    position: relative
}

.divider-block {
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    top: 11px;
    font-style: italic;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px
}

.ryft-modal-anchor {
    position: relative;
    width: 1px;
    height: 1px
}

.ryft-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 9999
}

.ryft-modal-window {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 400px;
    height: 100%;
    max-height: 600px;
    background-color: #fff;
    border-radius: 4px;
    z-index: 9999;
    padding: 20px;
    box-shadow: 0 14px 17px 7px rgba(0,0,0,.18);
    display: grid;
    place-items: center
}

@media(max-width: 600px) {
    .ryft-modal-window {
        width:100%;
        height: 100%;
        max-width: none;
        max-height: none
    }
}

.ryft-threeds-modal-iframe {
    width: 100%;
    height: 100%;
    border: none;
    display: none
}

.ryft-modal-loading {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    place-items: center;
    grid-gap: 20px
}

.ryft-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-family: "Open Sans",sans-serif
}

.ryft-loading-spinner {
    display: block;
    margin: 0 auto;
    width: 70px;
    height: 70px;
    animation: spin 1s linear infinite
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

.ryft-pay-card {
    cursor: pointer;
    height: 40px;
    padding: 0 12px;
    display: grid;
    grid-template-columns: 36px 1fr 2fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "scheme last4 expiry-date-label" "scheme last4 expiry-date";
    border: 1px solid #d4d4d4;
    margin-bottom: 2px;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.07))
}

.ryft-pay-card-selected {
    border: 1px solid #5AB26D !important;
    background-color: #DFFFE0 !important; 
}

.ryft-pay-card-scheme {
    grid-area: scheme;
    background-repeat: no-repeat;
    background-position: 0 50%
}

.ryft-pay-card-scheme-visa {
    background-image: url("${AppConfig.baseUrl}/${AppConfig.embeddedVersion}/images/visa.svg")
}

.ryft-pay-card-scheme-mastercard {
    background-image: url("${AppConfig.baseUrl}/${AppConfig.embeddedVersion}/images/mastercard.svg")
}

.ryft-pay-card-scheme-amex {
    background-image: url("${AppConfig.baseUrl}/${AppConfig.embeddedVersion}/images/americanexpress.svg")
}

.ryft-pay-card-schemedinersclub {
    background-image: url("${AppConfig.baseUrl}/${AppConfig.embeddedVersion}/images/dinersclub.svg")
}

.ryft-pay-card-scheme-discover {
    background-image: url("${AppConfig.baseUrl}/${AppConfig.embeddedVersion}/images/discover.svg")
}

.ryft-pay-card-schemejcb {
    background-image: url("${AppConfig.baseUrl}/${AppConfig.embeddedVersion}/images/jcb.svg")
}

.ryft-pay-card-scheme-unknown {
    background-image: url("${AppConfig.baseUrl}/${AppConfig.embeddedVersion}/images/unknown.svg")
}

.ryft-pay-card-single {
    grid-template-columns: 36px 120px 1fr 1fr;
    grid-template-areas: "scheme obfuscated last4 expiry-date-label" "scheme obfuscated last4 expiry-date"
}

.ryft-pay-card-single-amex {
    grid-template-columns: 36px 110px 1fr 1fr;
    grid-template-areas: "scheme obfuscated last4 expiry-date-label" "scheme obfuscated last4 expiry-date"
}

.ryft-pay-card-obfuscated {
    grid-area: obfuscated;
    display: grid;
    padding: 10px 0 12px 0;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    user-select: none
}

.ryft-pay-card-last4 {
    grid-area: last4;
    display: grid;
    align-self: center;
    padding: 12px 12px 12px 0;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    user-select: none
}

.ryft-pay-card-expiry-date-label {
    grid-area: expiry-date-label;
    display: grid;
    align-self: end;
    text-align: end;
    font-size: 7px;
    font-weight: 400;
    line-height: 10px;
    user-select: none
}

.ryft-pay-card-expiry-date {
    grid-area: expiry-date;
    display: grid;
    align-self: start;
    text-align: end;
    margin-top: -2px;
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    user-select: none
}

.ryft-pay-saved-cards-grid {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 12px
}

.ryft-pay-saved-cards-grid-more {
    max-width: 100%;
    max-height: 100%;
    overflow: scroll;
    grid-gap: 10px;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    overflow-x: auto
}

.ryft-pay-saved-cards-grid-more::-webkit-scrollbar {
    display: none
}

.ryft-pay-saved-cards-grid-more div.ryft-pay-card {
    min-width: 150px;
    scroll-snap-align: start;
    scroll-snap-stop:always}

.ryft-pay-subscription-grid {
    display: grid;
    grid-gap: 12px
}

.ryft-pay-subscription-grid-wallet-padding {
    padding-bottom: 20px
}

.ryft-pay-subscription-description {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    user-select: none;
    text-transform: uppercase
}

.ryft-pay-subscription-card {
    height: 40px;
    border: 1px solid #d4d4d4;
    padding: 0 12px;
    display: grid;
    grid-template-areas: "amountLabel startDateLabel timesLabel" "amount startDate times";
    grid-template-columns: 3fr 2fr 2fr;
    grid-template-rows: 1fr 1fr
}

.ryft-pay-subscription-card-label {
    margin-top: 8px;
    font-size: 8px;
    font-weight: 400;
    line-height: 11px;
    user-select: none;
    text-transform: uppercase
}

.ryft-pay-subscription-card-value {
    margin-bottom: 8px;
    font-size: 11px;
    font-weight: 600;
    line-height: 15px;
    user-select: none
}

.ryft-pay-subscription-card-amount-label {
    grid-area: amountLabel
}

.ryft-pay-subscription-card-start-date-label {
    grid-area: startDateLabel
}

.ryft-pay-subscription-card-times-label {
    grid-area: timesLabel
}

.ryft-pay-subscription-card-amount {
    grid-area: amount
}

.ryft-pay-subscription-card-start-date {
    grid-area: startDate
}

.ryft-pay-subscription-card-times {
    grid-area: times
}
`;
