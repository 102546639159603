import {
  addEventHandler,
  attemptPayment,
  getUserFacingErrorMessage,
  handleRequiredAction,
  init,
} from './client';
import { injectStyles, preloadImages } from './utils';
import { IRyft } from './types/global';

const Ryft: IRyft = {
  addEventHandler,
  attemptPayment,
  getUserFacingErrorMessage,
  handleRequiredAction,
  init,
};

injectStyles();
preloadImages();

if (typeof window !== 'undefined') {
  if (!window.Ryft) {
    (window as Window).Ryft = Ryft;
  } else {
    console.error('Ryft is already defined');
  }
}
