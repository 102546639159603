import {
  createGooglePayButton,
  createIsReadyToPayRequest,
  createPaymentDataRequest,
  getGooglePayEnvironment,
  onGooglePayPaymentAuthorized,
  storeWalletDetails,
} from '../../utils/';
import {
  GooglePayConfig,
  InitParams,
  NewCardDividerId,
  PaymentSession,
  TranslatedStrings,
} from '../../types';
import { sendExceptionToSentry, sendGooglePayReadyEvent } from '../../service';
import { getTranslatedStrings } from '../getTranslatedStrings';

const googlePayScriptUrl = 'https://pay.google.com/gp/p/js/pay.js';

export async function initGooglePay(
  initParams: InitParams,
  iframe: HTMLIFrameElement,
  paymentSession?: PaymentSession
): Promise<HTMLButtonElement | undefined> {
  const googlePayConfig = initParams.googlePayConfig;
  if (!googlePayConfig || !paymentSession) {
    return undefined;
  }

  const loadGooglePayScript = async (): Promise<void> => {
    if (!isScriptLoaded(googlePayScriptUrl)) {
      return new Promise<void>((resolve, reject): void => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = googlePayScriptUrl;
        script.onload = (): void => resolve();
        script.onerror = (): void => reject(new Error('Failed to load the Google Pay script'));
        document.head.appendChild(script);
      });
    }
  };

  try {
    await loadGooglePayScript();
  } catch (error) {
    sendExceptionToSentry(error);
    console.error('Error loading Google Pay script:', error);
    return;
  }

  const translatedStrings = getTranslatedStrings(initParams.localisationConfig.bcp47LanguageTag);

  const paymentsClient = new google.payments.api.PaymentsClient({
    environment: getGooglePayEnvironment(initParams.publicKey),
    paymentDataCallbacks: {
      onPaymentAuthorized: (
        paymentData: google.payments.api.PaymentData
      ): Promise<google.payments.api.PaymentAuthorizationResult> =>
        onGooglePayPaymentAuthorized(
          initParams.publicKey,
          initParams.clientSecret!,
          initParams.manuallyHandleActions,
          paymentData,
          storeWalletDetails(initParams.usage, initParams.paymentType),
          translatedStrings,
          initParams.accountId
        )
          .then(result => result)
          .catch(error => {
            sendExceptionToSentry(error);
            console.error('Google pay authorization failed', error);
            return { transactionState: 'ERROR' };
          }),
    },
  });

  const isReadyToPayRequest = createIsReadyToPayRequest(true, false);
  const response = await paymentsClient.isReadyToPay(isReadyToPayRequest);

  if (!response.result) {
    console.log(`Google Pay isReadyToPay returned false`);
    return undefined;
  }

  if (!googlePayConfig.merchantIdentifier) {
    initGooglePayInIFrame(googlePayConfig, iframe, translatedStrings, paymentSession);
    return;
  }

  const paymentDataRequest = createPaymentDataRequest(
    true,
    initParams.publicKey,
    googlePayConfig,
    paymentSession
  );

  return createGooglePayButton(
    googlePayConfig,
    paymentsClient,
    paymentDataRequest,
    initParams.styles,
    initParams.localisationConfig.bcp47LanguageTag
  );
}

function isScriptLoaded(src: string): boolean {
  return Array.from(document.getElementsByTagName('script')).some(script => script.src === src);
}

function initGooglePayInIFrame(
  googlePayConfig: GooglePayConfig,
  iframe: HTMLIFrameElement,
  translatedStrings: TranslatedStrings,
  paymentSession: PaymentSession
): void {
  const newCardDividerHeight = 42;
  const iframeHeightAdjustment = googlePayConfig.buttonConfiguration.height + newCardDividerHeight;
  const newIframeHeight = iframe.offsetHeight + iframeHeightAdjustment;
  iframe.style.height = `${newIframeHeight}px`;
  const newCardDividerBlock = document.getElementById(`${NewCardDividerId}-block`);
  if (newCardDividerBlock) {
    newCardDividerBlock.textContent = translatedStrings['pay-with-google-pay'];
  }
  sendGooglePayReadyEventToIFrame(iframe, paymentSession);
}

function sendGooglePayReadyEventToIFrame(
  iframe: HTMLIFrameElement,
  paymentSession: PaymentSession
): void {
  // send multiple times to be sure iframe has loaded
  let eventSentCount = 0;
  let intervalId = setInterval((): void => {
    sendGooglePayReadyEvent(paymentSession);
    if (
      ++eventSentCount >= 6 ||
      iframe.contentDocument?.getElementById('ryft-hosted-google-pay-button')
    ) {
      window.clearInterval(intervalId);
    }
  }, 500);
}
