import { Address, BillingAddressDisplay } from '../types';

export function validateAddress(address: Address, display: BillingAddressDisplay): boolean {
  const applyStateValidation = address.country === 'US' || address.country === 'CA';
  const fullAddressRequired = display === 'full';
  return (
    validateAddressFirstName(address.firstName, fullAddressRequired) &&
    validateAddressLastName(address.lastName, fullAddressRequired) &&
    validateAddressLineOne(address.lineOne, fullAddressRequired) &&
    validateAddressLineTwo(address.lineTwo, false) &&
    validateAddressCity(address.city, fullAddressRequired) &&
    (applyStateValidation
      ? validateAddressState(address.region || '')
      : validateAddressRegion(address.region, false)) &&
    validateAddressPostalZipCode(address.postalCode, true) &&
    validateAddressCountry(address.country || '')
  );
}

export function validateAddressFirstName(firstName: string | undefined, required = false): boolean {
  return validateString(firstName, 1, 80, required);
}

export function validateAddressLastName(lastName: string | undefined, required = false): boolean {
  return validateString(lastName, 1, 80, required);
}

export function validateAddressPostalZipCode(
  postalZipCode: string | undefined,
  required = true
): boolean {
  return (
    (!required && !postalZipCode) ||
    (!!postalZipCode && /^[A-Za-z0-9 -]{2,16}$/.test(postalZipCode.trim()))
  );
}

export function validateAddressLineOne(lineOne: string | undefined, required = false): boolean {
  return validateString(lineOne, 2, 80, required);
}

export function validateAddressLineTwo(lineTwo: string | undefined, required = false): boolean {
  return validateString(lineTwo, 2, 80, required);
}

export function validateAddressCity(city: string | undefined, required = false): boolean {
  return validateString(city, 2, 50, required);
}

export function validateAddressRegion(region: string | undefined, required = false): boolean {
  return validateString(region, 1, 80, required);
}

export function validateAddressState(state: string): boolean {
  return state.length === 2;
}

export function validateAddressCountry(country: string): boolean {
  return country.length === 2;
}

function validateString(
  str: string | undefined,
  minLength = 1,
  maxLength = Infinity,
  required = false
): boolean {
  return (
    (!required && !str) ||
    (!!str && str?.trim().length !== 0 && str.length >= minLength && str.length <= maxLength)
  );
}
