import { createElement } from './createElement';
import { TranslatedStrings, Styles } from '../../types';

interface CreateLabelAndInputOptions {
  id: string;
  labelText?: string;
  value?: string;
  validationFunction: (value: string | undefined, required: boolean) => boolean;
  onInputFunction?: () => void;
  required: boolean;
  placeholder?: string;
  styles: Styles;
  translatedStrings: TranslatedStrings;
}

export function createLabelAndInput({
  id,
  labelText,
  value,
  validationFunction,
  onInputFunction,
  required,
  placeholder,
  styles,
  translatedStrings,
}: CreateLabelAndInputOptions): HTMLElement {
  const div = createElement('div');
  div.classList.add('ryft-form-group');
  div.classList.add(id);
  const labelDiv = createElement('div');
  labelDiv.classList.add('ryft-label-group');
  const label = createElement('label', { for: id }, labelText);
  label.style.color = styles.color;
  const optionalDiv = createElement('div');
  optionalDiv.classList.add('ryft-optional');
  optionalDiv.style.color = styles.color;
  optionalDiv.textContent = required ? '' : translatedStrings['optional'];
  if (labelText) {
    labelDiv.appendChild(label);
    labelDiv.appendChild(optionalDiv);
  }
  const input = createElement('input', {
    type: 'text',
    id: id,
    placeholder: placeholder || '',
  }) as HTMLInputElement;
  input.classList.add('form-control');
  input.style.borderColor = styles.borderColor;
  input.style.color = styles.color;
  input.style.backgroundColor = styles.backgroundColor;
  input.style.borderRadius = styles.borderRadius;
  input.style.padding = styles.padding;

  let timeoutId: number | undefined;

  input.oninput = function (e: Event): void {
    const value = (e.target as HTMLInputElement).value;

    clearTimeout(timeoutId);
    timeoutId = window.setTimeout(function () {
      const isValid = validationFunction(value, required);
      input.style.borderColor = isValid ? styles.borderColor : '#ed5053';
      onInputFunction?.();
    }, 500);
  };

  if (value) {
    input.value = value;
    input.dispatchEvent(new Event('input'));
  }
  div.appendChild(labelDiv);
  div.appendChild(input);
  return div;
}
