import { InitParams, TranslatedStrings, PaymentMethod, Styles } from '../../types';
import { addNewCardDividerIfNotPresent, createDivider, createElement } from '../../utils';
import { getSelectedPaymentMethod, setSelectedPaymentMethod } from '../../state';
import { sendPaymentMethodSelectionChangedEvent } from '../../service';
import { validateCardExpiryDate } from '../../validators';
import { getTranslatedStrings } from '../getTranslatedStrings';

export function initSavedCards(
  initParams: InitParams,
  iframe: HTMLIFrameElement,
  payGrid: HTMLDivElement
): void {
  if (initParams.usage === 'setupCard' || !initParams.customerPaymentMethods) {
    return;
  }
  const paymentMethods = initParams.customerPaymentMethods;
  if (!paymentMethods || paymentMethods.length < 1) {
    return;
  }

  const translatedStrings = getTranslatedStrings(initParams.localisationConfig.bcp47LanguageTag);
  const styles = initParams.styles;
  const savedCardsGrid = createSavedCardsGrid(paymentMethods, translatedStrings, styles);
  const divider = createDivider(
    'ryft-pay-saved-cards-divider',
    translatedStrings['ryft-pay-saved-cards-divider'],
    styles
  );
  payGrid.append(divider);
  payGrid.append(savedCardsGrid);
  addNewCardDividerIfNotPresent(iframe, styles, translatedStrings);
}

function createSavedCardsGrid(
  paymentMethods: PaymentMethod[],
  translatedStrings: TranslatedStrings,
  styles: Styles
): HTMLDivElement {
  const savedCardsGrid = createElement('div', {
    id: 'ryft-pay-saved-cards-grid',
    class: 'ryft-pay-saved-cards-grid',
  });
  if (paymentMethods.length > 2) {
    savedCardsGrid.classList.add('ryft-pay-saved-cards-grid-more');
  }
  for (let i = 0; i < paymentMethods.length; i++) {
    const paymentMethod = paymentMethods[i];
    const savedCard = createSavedCard(paymentMethod, paymentMethods, translatedStrings, styles);
    savedCardsGrid.prepend(savedCard);
  }
  return savedCardsGrid;
}

function createSavedCard(
  paymentMethod: PaymentMethod,
  paymentMethods: PaymentMethod[],
  translatedStrings: TranslatedStrings,
  styles: Styles
): HTMLDivElement {
  const { card, id } = paymentMethod;
  const cardDiv = createElement('div', {
    id,
    name: 'ryft-pay-card',
    class: 'ryft-pay-card',
  });
  cardDiv.style.borderColor = styles.borderColor;
  cardDiv.style.color = styles.color;
  cardDiv.style.backgroundColor = styles.backgroundColor;
  cardDiv.style.borderRadius = styles.borderRadius;
  if (paymentMethods.length === 1) {
    const isAmex = paymentMethod.card.scheme === 'Amex';
    const maskedPanPrefix: string = isAmex ? '**** ****** *' : '**** **** ****';
    cardDiv.classList.add(isAmex ? 'ryft-pay-card-single-amex' : 'ryft-pay-card-single');
    const cardObfuscatedDiv = createElement(
      'div',
      { class: 'ryft-pay-card-obfuscated' },
      maskedPanPrefix
    );
    cardDiv.prepend(cardObfuscatedDiv);
  }
  const cardSchemeDiv = createElement('div', {
    class: `ryft-pay-card-scheme ryft-pay-card-scheme-${card.scheme.toLowerCase()}`,
  });
  const cardLast4Div = createElement('div', { class: 'ryft-pay-card-last4' }, card.last4);
  const cardExpiryDateLabelDiv = createElement(
    'div',
    { class: 'ryft-pay-card-expiry-date-label' },
    translatedStrings['expires-end']
  );
  const cardExpiryDateDiv = createElement(
    'div',
    { class: 'ryft-pay-card-expiry-date' },
    `${card.expiryMonth}/${card.expiryYear.substring(2)}`
  );
  cardDiv.prepend(cardExpiryDateLabelDiv);
  cardDiv.prepend(cardExpiryDateDiv);
  cardDiv.prepend(cardLast4Div);
  cardDiv.prepend(cardSchemeDiv);
  cardDiv.onclick = function (e: MouseEvent): void {
    const clickedElement = e.target as HTMLElement;
    const clickedPaymentMethodElement = clickedElement?.id
      ? clickedElement
      : clickedElement.parentElement;
    if (!clickedPaymentMethodElement?.id) {
      return;
    }
    const existingSelectedPaymentMethod = getSelectedPaymentMethod();
    const newPaymentMethodSelected =
      existingSelectedPaymentMethod?.id !== clickedPaymentMethodElement.id;
    deselectPaymentMethod(existingSelectedPaymentMethod, newPaymentMethodSelected);
    if (newPaymentMethodSelected) {
      selectPaymentMethod(paymentMethods, clickedPaymentMethodElement);
    }
  };

  return cardDiv;
}

export function deselectPaymentMethod(
  paymentMethod: PaymentMethod | undefined,
  newPaymentMethodSelected: boolean
): void {
  if (!paymentMethod) {
    return;
  }
  const existingCardDiv = document.getElementById(paymentMethod.id);
  existingCardDiv?.classList.remove('ryft-pay-card-selected');
  setSelectedPaymentMethod(undefined);
  if (!newPaymentMethodSelected) {
    sendPaymentMethodSelectionChangedEvent(false, undefined);
  }
}

function selectPaymentMethod(
  paymentMethods: PaymentMethod[],
  clickedPaymentMethodElement: HTMLElement
): void {
  clickedPaymentMethodElement.classList.add('ryft-pay-card-selected');
  const newPaymentMethod = paymentMethods.find(pm => pm.id === clickedPaymentMethodElement.id);
  const expirationValid = newPaymentMethod
    ? validateCardExpiryDate(
        newPaymentMethod.card.expiryMonth,
        newPaymentMethod.card.expiryYear,
        new Date()
      )
    : false;
  setSelectedPaymentMethod(newPaymentMethod);
  sendPaymentMethodSelectionChangedEvent(expirationValid, newPaymentMethod);
}
