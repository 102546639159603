import { handleRequiredAction } from './handleRequiredAction';
import {
  getBillingAddressForPayment,
  getEnteredBillingAddress,
  getThreeDsRequestDetails,
} from '../utils/';
import { AttemptPaymentRequest, PaymentSession, RequiredAction, RyftApiError } from '../types';
import { getInitParams, getSelectedPaymentMethod, updateInitParams } from '../state';
import { validateClientSecret } from '../validators';
import {
  configureSentry,
  internalAttemptPayment,
  sendAttemptPaymentEvent,
  sendErrorToSentry,
} from '../service';

export const attemptPayment = async (
  request?: AttemptPaymentRequest
): Promise<PaymentSession | RequiredAction | undefined> => {
  const initParams = getInitParams();
  if (!initParams) {
    const message = 'You must call Ryft.init() prior to calling Ryft.attemptPayment()';
    console.error(message);
    sendErrorToSentry(message);
    throw new Error(message);
  }

  let { clientSecret, publicKey, accountId, manuallyHandleActions, fieldCollectionConfig } =
    initParams;

  if (request?.clientSecret) {
    await configureSentry(publicKey, request.clientSecret, accountId);
    if (!validateClientSecret(request.clientSecret)) {
      const message = `Ryft.attemptPayment() clientSecret: ${request.clientSecret} is invalid`;
      sendErrorToSentry(message);
      console.error(message);
      throw new Error(message);
    }
    clientSecret = request.clientSecret;
    updateInitParams({ clientSecret });
  }

  if (!clientSecret) {
    const message = 'Ryft.attemptPayment() clientSecret is missing';
    sendErrorToSentry(message);
    console.error(message);
    throw new Error(message);
  }

  const enteredBillingAddress = getEnteredBillingAddress();
  const billingAddress = getBillingAddressForPayment(
    fieldCollectionConfig.billingAddressConfig,
    enteredBillingAddress
  );

  const selectedPaymentMethod = getSelectedPaymentMethod();
  if (selectedPaymentMethod) {
    const attemptPaymentRequest = {
      clientSecret,
      billingAddress,
      paymentMethod: {
        id: selectedPaymentMethod.id,
      },
      threeDsRequestDetails: getThreeDsRequestDetails(),
    };
    const apiResponse = await internalAttemptPayment(publicKey, attemptPaymentRequest, accountId);
    const paymentSession = apiResponse as PaymentSession;
    const apiError = apiResponse as RyftApiError;
    if (apiError.code) {
      throw new Error('An unexpected error occurred, please try again or contact support.');
    }
    if (manuallyHandleActions) {
      return paymentSession;
    }
    return await handleRequiredAction(paymentSession);
  } else {
    const channel = new MessageChannel();
    return new Promise((resolve, reject): void => {
      channel.port1.onmessage = ({ data }): void => {
        channel.port1.close();
        if (data.error) {
          reject(data.error);
        } else {
          const paymentSession = data.result;
          if (manuallyHandleActions) {
            resolve(paymentSession);
          } else {
            handleRequiredAction(paymentSession).then(resolve).catch(reject);
          }
        }
      };

      sendAttemptPaymentEvent(
        publicKey,
        clientSecret!,
        [channel.port2],
        accountId,
        request?.customerEmail,
        billingAddress
      );
    });
  }
};
