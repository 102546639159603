export function initialiseThreeDsChallengeForm(
  threeDsChallengeIFrameId: string,
  acsUrl?: string,
  cReq?: string
): HTMLFormElement {
  const threeDsChallengeFormId = 'ryft-threeDSChallengeForm';
  document.getElementById(threeDsChallengeFormId)?.remove();
  const form = Object.assign(document.createElement('form'), {
    id: threeDsChallengeFormId,
    enctype: 'application/x-www-form-urlencoded;charset=UTF-8',
    style: 'display: none;',
    method: 'post',
    action: acsUrl,
    target: threeDsChallengeIFrameId,
  });
  document.body.appendChild(form);

  const cReqInput = Object.assign(document.createElement('input'), {
    id: 'ryft-threeDsChallengeCReqInput',
    type: 'hidden',
    name: 'creq',
    value: cReq,
  });
  form.appendChild(cReqInput);

  return form;
}
