import { createElement } from './createElement';
import { Styles } from '../../types';

export function createDivider(id: string, text: string, styles: Styles): HTMLElement {
  const divider = createElement('div', { id, class: 'divider' });
  const dividerBlock = createElement('span', { id: `${id}-block`, class: 'divider-block' }, text);
  divider.appendChild(dividerBlock);
  divider.classList.add('form-control');
  divider.style.borderBottom = `1px solid ${styles.dividerLineColor}`;
  dividerBlock.style.backgroundColor = styles.bodyColor;
  dividerBlock.style.color = styles.dividerColor;
  return divider;
}
