import { validatePublicKey } from './validatePublicKey';
import { validateClientSecret } from './validateClientSecret';
import { validateAccountId } from './validateAccountId';
import {
  ApplePayConfig,
  GooglePayConfig,
  InitRequest,
  LocalisationRequest,
  StyleRequest,
  validationFail,
  ValidationResult,
  validationSuccess,
  ValidBillingAddressDisplays,
  ValidPaymentTypes,
  ValidUsages,
} from '../types';
import { validateBCP47LanguageTag } from './validateBCP47LanguageTag';
import { sendWarningToSentry } from '../service';
import { validateSubscriptionJson } from './validateSubscriptionJson';
import { validatePaymentMethodsJson } from './validatePaymentMethodsJson';

export function validateInitRequest(initRequest: InitRequest): ValidationResult {
  const validFields = [
    'publicKey',
    'clientSecret',
    'accountId',
    'manuallyHandleActions',
    'localisation',
    'style',
    'applePay',
    'googlePay',
    'fieldCollection',
    'subscription',
    'usage',
    'paymentType',
    'customerPaymentMethods',
  ];
  const requiredFields = ['publicKey'];
  const requestFieldsValidationResult = validateFields(
    validFields,
    requiredFields,
    initRequest,
    'init request',
    true
  );
  if (!requestFieldsValidationResult.valid) {
    return requestFieldsValidationResult;
  }

  if (!validatePublicKey(initRequest.publicKey)) {
    return validationFail(`Ryft.init() publicKey: ${initRequest.publicKey} is invalid`);
  }

  if (initRequest.clientSecret && !validateClientSecret(initRequest.clientSecret)) {
    return validationFail(`Ryft.init() clientSecret: ${initRequest.clientSecret} is invalid`);
  }

  if (initRequest.accountId && !validateAccountId(initRequest.accountId)) {
    return validationFail(`Ryft.init() accountId: ${initRequest.accountId} is invalid`);
  }

  if (initRequest.localisation) {
    const validLocalisationFields: (keyof LocalisationRequest)[] = [
      'language',
      'cardNumberPlaceholder',
      'expiryMonthPlaceholder',
      'expiryYearPlaceholder',
      'cvvPlaceholder',
      'nameOnCardPlaceholder',
    ];

    const localisationFieldsValidationResult = validateFields(
      validLocalisationFields,
      [],
      initRequest.localisation,
      'localisation'
    );
    if (!localisationFieldsValidationResult.valid) {
      return localisationFieldsValidationResult;
    }

    if (
      initRequest.localisation.language &&
      !validateBCP47LanguageTag(initRequest.localisation.language)
    ) {
      logWarning(
        `Ryft.init() localisation.language: ${initRequest.localisation.language} is invalid, expected a valid BCP47 language tag`
      );
    }
  }

  if (initRequest.style) {
    const validStyleFields: (keyof StyleRequest)[] = [
      'borderRadius',
      'backgroundColor',
      'borderColor',
      'padding',
      'color',
      'focusColor',
      'bodyColor',
      'dividerLineColor',
      'dividerColor',
    ];

    const styleFieldsValidationResult = validateFields(
      validStyleFields,
      [],
      initRequest.style,
      'style'
    );
    // Note, we don't validate css colors due to the wide range of valid values: https://www.w3schools.com/cssref/css_colors_legal.php
    if (!styleFieldsValidationResult.valid) {
      return styleFieldsValidationResult;
    }
  }

  if (initRequest.googlePay) {
    const validGooglePayFields: (keyof GooglePayConfig)[] = [
      'merchantIdentifier',
      'merchantName',
      'merchantCountryCode',
      'buttonConfiguration',
    ];
    const requiredGooglePayFields: (keyof GooglePayConfig)[] = [
      'merchantIdentifier',
      'merchantName',
      'merchantCountryCode',
    ];
    const googlePayFieldsValidationResult = validateFields(
      validGooglePayFields,
      requiredGooglePayFields,
      initRequest.googlePay,
      'googlePay'
    );
    if (!googlePayFieldsValidationResult.valid) {
      return googlePayFieldsValidationResult;
    }
  }

  if (initRequest.applePay) {
    const validApplePayFields: (keyof ApplePayConfig)[] = [
      'merchantName',
      'merchantCountryCode',
      'buttonConfiguration',
    ];
    const requiredApplePayFields: (keyof ApplePayConfig)[] = [
      'merchantName',
      'merchantCountryCode',
    ];
    const applePayFieldsValidationResult = validateFields(
      validApplePayFields,
      requiredApplePayFields,
      initRequest.applePay,
      'applePay'
    );
    if (!applePayFieldsValidationResult.valid) {
      return applePayFieldsValidationResult;
    }
  }

  if (initRequest.fieldCollection?.billingAddress) {
    if (
      !new Set(ValidBillingAddressDisplays).has(initRequest.fieldCollection.billingAddress.display)
    ) {
      logWarning(
        `Ryft.init() fieldCollection.billingAddress.display: ${initRequest.fieldCollection.billingAddress.display} is invalid, expected one of: ${Array.from(
          ValidBillingAddressDisplays
        ).join(', ')}. Defaulting to 'hidden'`
      );
    }
  }

  if (initRequest.subscription?.rawJson) {
    if (!validateSubscriptionJson(initRequest.subscription.rawJson, initRequest.paymentType)) {
      logWarning(`Ryft.init() subscription.rawJson is invalid or paymentType was not 'Recurring'`);
    }
  }

  if (initRequest.usage) {
    if (!new Set(ValidUsages).has(initRequest.usage)) {
      logWarning(
        `Ryft.init() usage: ${initRequest.usage} is invalid, expected one of: ${Array.from(ValidUsages).join(', ')}. Defaulting to 'payment'`
      );
    }
  }

  if (initRequest.paymentType) {
    if (!new Set(ValidPaymentTypes).has(initRequest.paymentType)) {
      logWarning(
        `Ryft.init() paymentType: ${initRequest.paymentType} is invalid, expected one of: ${Array.from(ValidPaymentTypes).join(', ')}. Defaulting to 'Standard'`
      );
    }
  }

  if (initRequest.customerPaymentMethods?.rawJson) {
    if (!validatePaymentMethodsJson(initRequest.customerPaymentMethods.rawJson)) {
      logWarning(
        `Ryft.init() customerPaymentMethods.rawJson is invalid, expected an object containing 'items' which is a list of payment methods`
      );
    }
  }

  return validationSuccess();
}

function validateFields(
  validFields: string[],
  requiredFields: string[],
  actualFields: object,
  objectName: string,
  failOnMissingFields = false
): ValidationResult {
  for (const key in actualFields) {
    if (!validFields.includes(key)) {
      logWarning(
        `Ryft.init() unknown field: '${key}' under ${objectName}, valid fields are: ${validFields.join(', ')}`
      );
    }
  }
  const missingFields = requiredFields.filter(field => !(field in actualFields));
  if (missingFields.length > 0) {
    const message = `Ryft.init() missing required ${objectName} field(s): ${missingFields.join(', ')}`;
    if (failOnMissingFields) {
      return validationFail(message);
    }
    logWarning(message);
  }
  return validationSuccess();
}

function logWarning(message: string): void {
  console.warn(message);
  sendWarningToSentry(message);
}
