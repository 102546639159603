import { waitForThreeDsChallengeNotification } from './waitForThreeDsChallengeNotification';
import { removeThreeDsModal } from './removeThreeDsModal';
import { ContinuePaymentRequest, PaymentSession, RyftApiError } from '../../types';
import { continuePayment, sendExceptionToSentry } from '../../service';

export async function handleThreeDsChallengeNotification(
  publicKey: string,
  clientSecret: string,
  accountId?: string
): Promise<PaymentSession | RyftApiError> {
  let transStatus = 'U';
  let threeDSServerTransID = null;
  try {
    const tenMinutesTimeout = 1000 * 600;
    const threeDsChallengeMessage = await waitForThreeDsChallengeNotification(tenMinutesTimeout);
    transStatus = threeDsChallengeMessage.transStatus;
    threeDSServerTransID = threeDsChallengeMessage.threeDSServerTransID;
  } catch (error) {
    sendExceptionToSentry(error);
    console.error(`timeout waiting for 3ds challenge completion ${error}`);
  } finally {
    removeThreeDsModal();
  }
  const challengeResult = {
    transStatus: transStatus,
    threeDSServerTransID: threeDSServerTransID,
  };
  const encoded = window.btoa(JSON.stringify(challengeResult));
  const request: ContinuePaymentRequest = {
    clientSecret: clientSecret,
    threeDs: {
      challengeResult: encoded,
    },
  };
  return await continuePayment(publicKey, request, accountId);
}
