import { EventHandlers, Events } from '../types';
import { getInitParams, internalAddEventHandler } from '../state';
import { sendErrorToSentry } from '../service';

export const addEventHandler = <K extends keyof Events>(
  eventName: K,
  handler: EventHandlers[K]
): void => {
  const initParams = getInitParams();
  if (!initParams) {
    const message = 'You must call Ryft.init() prior to calling Ryft.addEventHandler()';
    console.error(message);
    sendErrorToSentry(message);
    return;
  }
  internalAddEventHandler(eventName, handler);
};
