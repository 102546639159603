﻿import { PaymentMethod } from '../types';

let selectedPaymentMethod: PaymentMethod | undefined;

export const getSelectedPaymentMethod = (): PaymentMethod | undefined => {
  return selectedPaymentMethod;
};

export const setSelectedPaymentMethod = (
  newSelectedPaymentMethod: PaymentMethod | undefined
): void => {
  selectedPaymentMethod = newSelectedPaymentMethod;
};
