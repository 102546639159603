export function initialiseThreeDsMethodForm(
  uniqueId: string,
  threeDsMethodUrl: string,
  threeDsMethodSignature: string,
  threeDsMethodData: string | undefined
): HTMLFormElement {
  const iFrameId = 'ryft-threeDSMethodIframe';
  document.getElementById(iFrameId)?.remove();
  const iframe = Object.assign(document.createElement('iframe'), {
    id: iFrameId,
    name: 'ryft-threeDSMethodIframe',
    style: 'border: 0px; width: 0px; height: 0px;',
  });
  document.body.appendChild(iframe);

  const form = Object.assign(document.createElement('form'), {
    id: 'ryft-threeDSMethodForm',
    enctype: 'application/x-www-form-urlencoded;charset=UTF-8',
    style: 'display: none;',
    method: 'post',
    target: iFrameId,
    action: threeDsMethodUrl,
  });
  document.body.appendChild(form);

  const uniqueIdInput = Object.assign(document.createElement('input'), {
    id: 'ryft-threeDsMethodUniqueIdInput',
    type: 'hidden',
    name: 'unique_id',
    value: uniqueId,
  });
  form.appendChild(uniqueIdInput);

  const signatureInput = Object.assign(document.createElement('input'), {
    id: 'ryft-threeDsMethodSignatureInput',
    type: 'hidden',
    name: 'signature',
    value: threeDsMethodSignature,
  });
  form.appendChild(signatureInput);

  const threeDSMethodDataInput = Object.assign(document.createElement('input'), {
    id: 'ryft-threeDsMethodDataInput',
    type: 'hidden',
    name: 'threeDSMethodData',
    value: threeDsMethodData || '',
  });
  form.appendChild(threeDSMethodDataInput);
  return form;
}
