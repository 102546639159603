import {
  ALLOWED_AUTH_METHODS,
  ALLOWED_CARD_NETWORKS,
  API_MINOR_VERSION,
  API_MAJOR_VERSION,
  getBillingAddressParameters,
  PAYMENT_METHOD_CARD,
} from './googlePayConstants';

export function createIsReadyToPayRequest(
  billingAddressRequired: boolean,
  existingPaymentMethodRequired: boolean
): google.payments.api.IsReadyToPayRequest {
  return {
    apiVersion: API_MAJOR_VERSION,
    apiVersionMinor: API_MINOR_VERSION,
    allowedPaymentMethods: [
      {
        type: PAYMENT_METHOD_CARD,
        parameters: {
          allowedAuthMethods: ALLOWED_AUTH_METHODS,
          allowedCardNetworks: ALLOWED_CARD_NETWORKS,
          billingAddressRequired,
          billingAddressParameters: getBillingAddressParameters(billingAddressRequired),
        },
      },
    ],
    existingPaymentMethodRequired,
  };
}
