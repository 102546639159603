﻿export const validateCardExpiryDate = (
  expiryMonth: string,
  expiryYear: string,
  now: Date
): boolean => {
  if (!expiryMonth || !expiryYear) {
    return false;
  }
  const monthAsNumber = +expiryMonth;
  if (monthAsNumber < 1 || monthAsNumber > 12) {
    return false;
  }
  const yearAsNumber = +expiryYear;
  if (yearAsNumber < 2020 || yearAsNumber > 9999) {
    return false;
  }
  const expiryDate = new Date(yearAsNumber, monthAsNumber, 1);
  return now < expiryDate;
};
