import { createGooglePayAttemptPaymentRequest } from './createGooglePayAttemptPaymentRequest';
import {
  internalAttemptPayment,
  sendWalletPaymentRequiredActionEvent,
  sendWalletPaymentSessionResultEvent,
} from '../../service';
import { PaymentSession, RyftApiError, TranslatedStrings } from '../../types';

export async function onGooglePayPaymentAuthorized(
  publicKey: string,
  clientSecret: string,
  manuallyHandleActions: boolean,
  paymentData: google.payments.api.PaymentData,
  storeWalletDetails: boolean,
  translatedStrings: TranslatedStrings,
  accountId?: string
): Promise<google.payments.api.PaymentAuthorizationResult> {
  const attemptPaymentRequest = createGooglePayAttemptPaymentRequest(
    clientSecret,
    paymentData,
    storeWalletDetails
  );

  const apiResponse = await internalAttemptPayment(publicKey, attemptPaymentRequest, accountId);
  const paymentSession = apiResponse as PaymentSession;
  const apiError = apiResponse as RyftApiError;
  if (apiError.code) {
    return {
      transactionState: 'ERROR',
      error: {
        intent: 'PAYMENT_AUTHORIZATION',
        message: 'An unexpected error occurred, please try again or contact support.',
        reason: 'OTHER_ERROR',
      },
    };
  }

  if (paymentSession.lastError) {
    return {
      transactionState: 'ERROR',
      error: {
        intent: 'PAYMENT_AUTHORIZATION',
        message:
          translatedStrings[paymentSession.lastError] ??
          'An unexpected error occurred, please try again or contact support.',
        reason: 'PAYMENT_DATA_INVALID',
      },
    };
  }
  if (manuallyHandleActions || !paymentSession.requiredAction) {
    sendWalletPaymentSessionResultEvent(paymentSession);
    return { transactionState: 'SUCCESS' };
  }
  sendWalletPaymentRequiredActionEvent(paymentSession);
  return { transactionState: 'SUCCESS' };
}
