import { isoCAStatesArr, isoCountriesArr, isoUSStatesArr } from '../../data';
import {
  createDivider,
  createElement,
  createLabelAndInput,
  createLabelAndSelect,
  getEnteredBillingAddress,
} from '../../utils';
import {
  BillingAddressDisplay,
  FieldCollectionId,
  InitParams,
  TranslatedStrings,
} from '../../types';
import {
  validateAddress,
  validateAddressCity,
  validateAddressFirstName,
  validateAddressLastName,
  validateAddressLineOne,
  validateAddressLineTwo,
  validateAddressPostalZipCode,
  validateAddressRegion,
} from '../../validators';
import { getInitParams } from '../../state';
import { sendBillingAddressValidationChangedEvent } from '../../service';
import { getTranslatedStrings } from '../getTranslatedStrings';

export function initFieldCollection(initParams: InitParams, iframe: HTMLIFrameElement): void {
  const billingAddressConfig = initParams.fieldCollectionConfig.billingAddressConfig;
  if (billingAddressConfig.display === 'hidden') {
    return;
  }

  const styles = initParams.styles;
  const translatedStrings = getTranslatedStrings(initParams.localisationConfig.bcp47LanguageTag);

  const initBillingAddressValue = billingAddressConfig.value;
  const display = billingAddressConfig.display;

  const billingAddress = createDivider(
    'ryft-pay-billing-address-divider',
    translatedStrings['billing-address'],
    styles
  );

  let addressFields: HTMLElement[] = [];
  if (display === 'full') {
    const nameDiv = createElement('div');
    nameDiv.classList.add('ryft-name-div');

    const label = createElement('label');
    label.textContent = translatedStrings['name'];
    label.style.color = styles.color;
    label.classList.add('ryft-label-div');

    const nameInputs = createElement('div');
    nameInputs.classList.add('ryft-name-inputs');

    const firstNameGroup = createLabelAndInput({
      id: 'ryft-billingAddress-firstName',
      value: initBillingAddressValue?.firstName,
      validationFunction: validateAddressFirstName,
      onInputFunction: function () {
        validateAndSendBillingAddressEvent(display);
      },
      required: true,
      placeholder: translatedStrings['first-name'],
      styles,
      translatedStrings,
    });
    const lastNameGroup = createLabelAndInput({
      id: 'ryft-billingAddress-lastName',
      value: initBillingAddressValue?.lastName,
      validationFunction: validateAddressLastName,
      onInputFunction: function () {
        validateAndSendBillingAddressEvent(display);
      },
      required: true,
      placeholder: translatedStrings['last-name'],
      styles,
      translatedStrings,
    });

    nameInputs.appendChild(firstNameGroup);
    nameInputs.appendChild(lastNameGroup);
    nameDiv.appendChild(nameInputs);
    addressFields = [label, nameDiv];
  }

  const countrySelectGroup = createLabelAndSelect({
    id: 'ryft-billingAddress-country',
    labelText: translatedStrings['country'],
    options: isoCountriesArr().map(country => ({
      value: country.code,
      name: country.name,
    })),
    value: 'code',
    onChangeFunction: function (country: string) {
      updateRegionSelection(country, null);
      validateAndSendBillingAddressEvent(display);
    },
    defaultValue: initBillingAddressValue?.country,
    optional: false,
    styles,
  });

  const postalCodeGroup = createLabelAndInput({
    id: 'ryft-billingAddress-postalZipCode',
    labelText: translatedStrings['postal-code'],
    value: initBillingAddressValue?.postalCode,
    validationFunction: validateAddressPostalZipCode,
    onInputFunction: function () {
      validateAndSendBillingAddressEvent(display);
    },
    required: true,
    styles,
    translatedStrings,
  });

  addressFields.push(countrySelectGroup, postalCodeGroup);
  if (display === 'full') {
    const addressLineOneGroup = createLabelAndInput({
      id: 'ryft-billingAddress-addressLineOne',
      labelText: translatedStrings['line-one'],
      value: initBillingAddressValue?.lineOne,
      validationFunction: validateAddressLineOne,
      onInputFunction: function () {
        validateAndSendBillingAddressEvent(display);
      },
      required: true,
      styles,
      translatedStrings,
    });
    const addressLineTwoGroup = createLabelAndInput({
      id: 'ryft-billingAddress-addressLineTwo',
      labelText: translatedStrings['line-two'],
      value: initBillingAddressValue?.lineTwo,
      validationFunction: validateAddressLineTwo,
      onInputFunction: function () {
        validateAndSendBillingAddressEvent(display);
      },
      required: false,
      styles,
      translatedStrings,
    });
    const addressCityGroup = createLabelAndInput({
      id: 'ryft-billingAddress-addressCity',
      labelText: translatedStrings['city'],
      value: initBillingAddressValue?.city,
      validationFunction: validateAddressCity,
      onInputFunction: function () {
        validateAndSendBillingAddressEvent(display);
      },
      required: true,
      styles,
      translatedStrings,
    });
    const addressRegionGroup = createLabelAndInput({
      id: 'ryft-billingAddress-addressRegion',
      labelText: getRegionLabel(translatedStrings),
      value: initBillingAddressValue?.region,
      validationFunction: validateAddressRegion,
      onInputFunction: function () {
        validateAndSendBillingAddressEvent(display);
      },
      required: false,
      styles,
      translatedStrings,
    });
    addressFields.push(
      addressLineOneGroup,
      addressLineTwoGroup,
      addressCityGroup,
      addressRegionGroup
    );
  }
  const fieldCollection = createElement('div', { id: FieldCollectionId });
  fieldCollection.appendChild(billingAddress);
  addressFields.forEach(field => fieldCollection.appendChild(field));
  iframe.insertAdjacentElement('afterend', fieldCollection);
  if (initBillingAddressValue?.country) {
    updateRegionSelection(initBillingAddressValue?.country, initBillingAddressValue?.region);
  }
}

export function updateRegionSelection(
  providedCountry: string,
  providedRegion: string | undefined | null
): void {
  const initParams = getInitParams()!;
  const translatedStrings = getTranslatedStrings(initParams.localisationConfig.bcp47LanguageTag);
  const regionLabel = getRegionLabel(translatedStrings, providedCountry);

  const addressStateElement = document.querySelector(
    '.ryft-form-group.ryft-billingAddress-addressState'
  );
  const display = initParams.fieldCollectionConfig.billingAddressConfig.display;
  const styles = initParams.styles;

  if (providedCountry === 'US' || providedCountry === 'CA') {
    const isoStates = providedCountry === 'US' ? isoUSStatesArr() : isoCAStatesArr();
    const stateSelect = createLabelAndSelect({
      id: 'ryft-billingAddress-addressState',
      labelText: regionLabel,
      options: isoStates.map(state => ({
        value: state.code,
        name: state.name,
      })),
      value: 'code',
      defaultValue: providedRegion || '',
      onChangeFunction: function (): void {
        validateAndSendBillingAddressEvent(display);
      },
      styles,
    });

    addressStateElement?.remove();
    document.querySelector('.ryft-form-group.ryft-billingAddress-addressRegion')?.remove();
    const lastElement = document.querySelector(
      display === 'full'
        ? '.ryft-form-group.ryft-billingAddress-addressCity'
        : '.ryft-form-group.ryft-billingAddress-postalZipCode'
    );
    lastElement?.insertAdjacentElement('afterend', stateSelect);
  } else {
    if (!addressStateElement) {
      return;
    }
    if (display === 'minimum') {
      addressStateElement.remove();
      return;
    }

    const validationFunction = validateAddressRegion;

    const addressRegion = createLabelAndInput({
      id: 'ryft-billingAddress-addressRegion',
      labelText: regionLabel,
      value: providedRegion || '',
      validationFunction,
      onInputFunction: function () {
        validateAndSendBillingAddressEvent(display);
      },
      required: false,
      styles,
      translatedStrings,
    });

    addressStateElement.insertAdjacentElement('beforebegin', addressRegion);
    addressStateElement.remove();
  }
}

function validateAndSendBillingAddressEvent(display: BillingAddressDisplay): void {
  const billingAddress = getEnteredBillingAddress();
  const isValid = billingAddress ? validateAddress(billingAddress, display) : false;
  sendBillingAddressValidationChangedEvent(isValid);
}

function getRegionLabel(translatedStrings: TranslatedStrings, country?: string): string {
  switch (country) {
    case 'US':
      return translatedStrings['state'];
    case 'CA':
      return translatedStrings['province'];
    case 'GB':
      return translatedStrings['county'];
    default:
      return translatedStrings['region'];
  }
}
