﻿import {
  ApplePayConfig,
  ApplePayRequest,
  BillingAddressConfig,
  GooglePayConfig,
  GooglePayRequest,
  InitParams,
  InitRequest,
  PaymentMethod,
  PaymentType,
  Styles,
  Subscription,
  SubscriptionRequest,
  Usage,
  ValidBillingAddressDisplays,
  ValidPaymentTypes,
  ValidUsages,
} from '../../types';
import { validateBCP47LanguageTag } from '../../validators';
import { validateSubscriptionJson } from '../../validators/validateSubscriptionJson';
import { validatePaymentMethodsJson } from '../../validators/validatePaymentMethodsJson';

const defaultStyles: Styles = {
  borderRadius: '4px',
  backgroundColor: '#FFF',
  borderColor: '#D9D9D9',
  padding: '12px',
  color: '#5A5A5A',
  focusColor: '#2581e3',
  bodyColor: '#FFF',
  dividerColor: '#939393',
  dividerLineColor: '#939393',
};

const defaultButtonHeight = 42;

export const mapInitRequest = (request: InitRequest): InitParams => {
  const bcp47LanguageTag = mapBcp47LanguageTag(request.localisation?.language);
  return {
    publicKey: request.publicKey,
    clientSecret: request.clientSecret,
    accountId: request.accountId,
    manuallyHandleActions: request.manuallyHandleActions || false,
    localisationConfig: {
      bcp47LanguageTag: bcp47LanguageTag,
      cardNumberPlaceholder: request.localisation?.cardNumberPlaceholder || undefined,
      expiryMonthPlaceholder: request.localisation?.expiryMonthPlaceholder || undefined,
      expiryYearPlaceholder: request.localisation?.expiryYearPlaceholder || undefined,
      cvvPlaceholder: request.localisation?.cvvPlaceholder || undefined,
      nameOnCardPlaceholder: request.localisation?.nameOnCardPlaceholder || undefined,
    },
    styles: request.style
      ? {
          borderRadius: mapPixelsStyleValue(defaultStyles.borderRadius, request.style.borderRadius),
          backgroundColor: request.style.backgroundColor || defaultStyles.backgroundColor,
          borderColor: request.style.borderColor || defaultStyles.borderColor,
          padding: mapPixelsStyleValue(defaultStyles.padding, request.style.padding),
          color: request.style.color || defaultStyles.color,
          focusColor: request.style.focusColor || defaultStyles.focusColor,
          bodyColor: request.style.bodyColor || defaultStyles.bodyColor,
          dividerColor: request.style.dividerColor || defaultStyles.dividerColor,
          dividerLineColor: request.style.dividerLineColor || defaultStyles.dividerLineColor,
        }
      : defaultStyles,
    applePayConfig: mapApplePayConfig(request.applePay, request.paymentType),
    googlePayConfig: mapGooglePayConfig(request.googlePay, request.paymentType),
    fieldCollectionConfig: {
      billingAddressConfig: mapBillingAddressConfig(request.fieldCollection?.billingAddress),
      collectNameOnCard: request.fieldCollection?.nameOnCard || false,
    },
    subscription: mapSubscription(request.subscription, request.paymentType),
    usage: mapUsage(request.usage),
    paymentType: mapPaymentType(request.paymentType),
    allowPaymentMethodStorage: request.customerPaymentMethods?.allowStorage || false,
    customerPaymentMethods: mapCustomerPaymentMethodsJson(request.customerPaymentMethods?.rawJson),
  };
};

function mapPixelsStyleValue(defaultPixels: string, pixels?: number): string {
  if (pixels === undefined) {
    return defaultPixels;
  }
  // Clients using the old embedded form might have passed in a string with 'px' suffix
  // as passing in a number didn't actually work
  const pixelsString = pixels.toString();
  if (pixelsString === '') {
    return defaultPixels;
  }
  if (pixelsString.endsWith('px')) {
    return pixelsString;
  }
  return `${pixels}px`;
}

function mapBcp47LanguageTag(inputLanguageTag?: string): string {
  const defaultLanguageTag = getBrowserLanguage() || 'en';
  return inputLanguageTag
    ? validateBCP47LanguageTag(inputLanguageTag)
      ? inputLanguageTag
      : defaultLanguageTag
    : defaultLanguageTag;
}

function mapApplePayConfig(
  request?: ApplePayRequest,
  paymentType?: PaymentType
): ApplePayConfig | undefined {
  if (!request || !request.merchantName || !request.merchantCountryCode) {
    return undefined;
  }
  return {
    merchantName: request.merchantName,
    merchantCountryCode: request.merchantCountryCode,
    buttonConfiguration: {
      height: mapStyleValueAsNumber(defaultButtonHeight, request.buttonConfiguration?.height),
      type:
        request.buttonConfiguration?.type || (paymentType === 'Recurring' ? 'subscribe' : 'plain'),
      color: request.buttonConfiguration?.color || 'black',
    },
  };
}

function mapGooglePayConfig(
  request?: GooglePayRequest,
  paymentType?: PaymentType
): GooglePayConfig | undefined {
  if (!request || !request.merchantName || !request.merchantCountryCode) {
    return undefined;
  }
  return {
    merchantIdentifier: request.merchantIdentifier,
    merchantName: request.merchantName,
    merchantCountryCode: request.merchantCountryCode,
    buttonConfiguration: {
      height: mapStyleValueAsNumber(defaultButtonHeight, request.buttonConfiguration?.height),
      type:
        request.buttonConfiguration?.type || (paymentType === 'Recurring' ? 'subscribe' : 'plain'),
      color: request.buttonConfiguration?.color || 'black',
    },
  };
}

function mapStyleValueAsNumber(defaultValue: number, value?: number): number {
  return !value ? defaultValue : +value || defaultValue;
}

function mapBillingAddressConfig(
  billingAddressConfig?: BillingAddressConfig
): BillingAddressConfig {
  const defaultBillingAddressConfig: BillingAddressConfig = {
    display: 'hidden',
  };
  return !billingAddressConfig
    ? defaultBillingAddressConfig
    : new Set(ValidBillingAddressDisplays).has(billingAddressConfig.display)
      ? billingAddressConfig
      : defaultBillingAddressConfig;
}

function mapSubscription(
  subscription?: SubscriptionRequest,
  paymentType?: string
): Subscription | undefined {
  return subscription?.rawJson && validateSubscriptionJson(subscription.rawJson, paymentType)
    ? JSON.parse(subscription.rawJson)
    : undefined;
}

function mapUsage(usage?: Usage): Usage {
  const defaultUsage: Usage = 'payment';
  return !usage ? defaultUsage : new Set(ValidUsages).has(usage) ? usage : defaultUsage;
}

function mapPaymentType(paymentType?: PaymentType): PaymentType {
  const defaultPaymentType: PaymentType = 'Standard';
  return !paymentType
    ? defaultPaymentType
    : new Set(ValidPaymentTypes).has(paymentType)
      ? paymentType
      : defaultPaymentType;
}

function mapCustomerPaymentMethodsJson(rawJson?: string): PaymentMethod[] {
  return rawJson && validatePaymentMethodsJson(rawJson) ? JSON.parse(rawJson).items : [];
}

function getBrowserLanguage(): string {
  return navigator.languages?.[0] ?? navigator.language;
}
