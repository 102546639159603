import { displayThreeDsModal } from './displayThreeDsModal';
import { removeThreeDsModal } from './removeThreeDsModal';
import { waitForEventWithTimeout } from './waitForEventWithTimeout';
import { ContinuePaymentRequest, IdentifyAction, PaymentSession, RyftApiError } from '../../types';
import {
  continuePayment,
  getPaymentSession,
  sendExceptionToSentry,
  sendErrorToSentry,
} from '../../service';
import { handleThreeDsRedirectAction } from './handleThreeDsRedirectAction';
import { handleThreeDsChallengeAction } from './handleThreeDsChallengeAction';
import { initialiseThreeDsMethodForm } from './initialiseThreeDsMethodForm';

interface MethodNotification {
  threeDSServerTransID: string;
}

export async function handleThreeDsIdentifyAction(
  identify: IdentifyAction,
  publicKey: string,
  clientSecret: string,
  accountId?: string
): Promise<PaymentSession | RyftApiError | undefined> {
  const { uniqueId, threeDsMethodUrl, threeDsMethodSignature, threeDsMethodData } = identify;
  const form = initialiseThreeDsMethodForm(
    uniqueId,
    threeDsMethodUrl,
    threeDsMethodSignature,
    threeDsMethodData
  );
  form.submit();
  const showThreeDsLoadingModal = !!identify.scheme;
  if (showThreeDsLoadingModal) {
    displayThreeDsModal(identify.scheme, undefined, undefined);
  }
  // newer 3ds, uses /continue-payment endpoint for 3ds method
  if (identify.threeDsMethodData) {
    return await handleThreeDsMethod(publicKey, clientSecret, accountId);
  }
  return await handleEcpFingerprint(showThreeDsLoadingModal, publicKey, clientSecret, accountId);
}

async function handleEcpFingerprint(
  showThreeDsLoadingModal: boolean,
  publicKey: string,
  clientSecret: string,
  accountId?: string
): Promise<PaymentSession | RyftApiError | undefined> {
  const apiResponse = await getPaymentSession(publicKey, clientSecret, accountId);
  const RyftApiError = apiResponse as RyftApiError;
  if (RyftApiError.code) {
    return RyftApiError;
  }
  const paymentSession = apiResponse as PaymentSession;
  if (paymentSession.status !== 'PendingAction') {
    removeThreeDsModal();
    return paymentSession;
  }
  const updatedActionType = paymentSession.requiredAction?.type;
  if (updatedActionType === 'Redirect') {
    handleThreeDsRedirectAction(paymentSession.requiredAction!.url!);
    return;
  }
  if (updatedActionType === 'Challenge') {
    return await handleThreeDsChallengeAction(
      paymentSession.requiredAction!.challenge!,
      showThreeDsLoadingModal,
      publicKey,
      clientSecret,
      accountId
    );
  }
}

async function submitThreeDsMethodIndicator(
  publicKey: string,
  clientSecret: string,
  accountId?: string
): Promise<PaymentSession | RyftApiError> {
  let threeDsCompInd = 'N';
  let threeDSServerTransID = null;
  try {
    const threeDsMethodNotification = await waitForThreeDsMethodNotification(10000);
    threeDsCompInd = 'Y';
    threeDSServerTransID = threeDsMethodNotification.threeDSServerTransID;
  } catch (error) {
    sendExceptionToSentry(error);
    console.error(`timeout waiting for 3ds method completion: ${error}`);
  }
  const fingerprintData = {
    threeDSServerTransID: threeDSServerTransID,
    threeDSCompInd: threeDsCompInd,
  };
  const fingerprint = window.btoa(JSON.stringify(fingerprintData));
  const request: ContinuePaymentRequest = {
    clientSecret: clientSecret,
    threeDs: {
      fingerprint: fingerprint,
    },
  };
  return await continuePayment(publicKey, request, accountId);
}

async function handleThreeDsMethod(
  publicKey: string,
  clientSecret: string,
  accountId?: string
): Promise<PaymentSession | RyftApiError | undefined> {
  const apiResponse = await submitThreeDsMethodIndicator(publicKey, clientSecret, accountId);
  const RyftApiError = apiResponse as RyftApiError;
  if (RyftApiError.code) {
    removeThreeDsModal();
    return RyftApiError;
  }
  const paymentSession = apiResponse as PaymentSession;
  if (paymentSession.status !== 'PendingAction') {
    removeThreeDsModal();
    return paymentSession;
  }
  if (paymentSession.requiredAction?.type === 'Identify') {
    removeThreeDsModal();
    const repeatIdentify = paymentSession.requiredAction.identify;
    return await handleRepeatThreeDsIdentifyAction(
      repeatIdentify!,
      publicKey,
      clientSecret,
      accountId
    );
  }
  if (paymentSession.requiredAction?.type === 'Challenge') {
    return await handleThreeDsChallengeAction(
      paymentSession.requiredAction!.challenge!,
      true,
      publicKey,
      clientSecret,
      accountId
    );
  }
  sendErrorToSentry(
    `Unexpected result from continue payment for 3ds identify flow. Payment session action is: ${paymentSession.requiredAction?.type}`
  );
}

async function handleRepeatThreeDsIdentifyAction(
  identify: IdentifyAction,
  publicKey: string,
  clientSecret: string,
  accountId?: string
): Promise<PaymentSession | RyftApiError | undefined> {
  const { uniqueId, threeDsMethodUrl, threeDsMethodSignature, threeDsMethodData } = identify;
  const form = initialiseThreeDsMethodForm(
    uniqueId,
    threeDsMethodUrl,
    threeDsMethodSignature,
    threeDsMethodData
  );
  form.submit();
  const showThreeDsLoadingModal = !!identify.scheme;
  if (showThreeDsLoadingModal) {
    displayThreeDsModal(identify.scheme, undefined, undefined);
  }
  const apiResponse = await submitThreeDsMethodIndicator(publicKey, clientSecret, accountId);
  const RyftApiError = apiResponse as RyftApiError;
  if (RyftApiError.code) {
    removeThreeDsModal();
    return RyftApiError;
  }
  const paymentSession = apiResponse as PaymentSession;
  if (paymentSession.status !== 'PendingAction') {
    removeThreeDsModal();
    return paymentSession;
  }
  if (paymentSession.requiredAction?.type === 'Challenge') {
    return await handleThreeDsChallengeAction(
      paymentSession.requiredAction!.challenge!,
      true,
      publicKey,
      clientSecret,
      accountId
    );
  }
  sendErrorToSentry(
    `Unexpected result from continue payment for 3ds identify (repeat) flow. Payment session action is: ${paymentSession.requiredAction?.type}`
  );
}

function waitForThreeDsMethodNotification(timeout: number): Promise<MethodNotification> {
  function predicate(event: MessageEvent): boolean {
    try {
      const eventData = event.data;
      if (typeof eventData === 'string') {
        const notification = JSON.parse(eventData);
        return 'threeDSServerTransID' in notification;
      }
      return false;
    } catch (error) {
      sendExceptionToSentry(error);
      return false;
    }
  }

  function mapEvent(event: MessageEvent): MethodNotification {
    return JSON.parse(event.data) as MethodNotification;
  }

  return waitForEventWithTimeout<MethodNotification>(
    window,
    'message',
    timeout,
    predicate,
    mapEvent
  );
}
