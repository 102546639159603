﻿import { ApplePaySessionResponse, CreateApplePaySessionRequest, RyftApiError } from '../../types';
import { createApplePaySession, sendExceptionToSentry } from '../../service';

export async function onValidateMerchant(
  publicKey: string,
  applePayMerchantName: string,
  applePaySession: ApplePaySession,
  accountId?: string
): Promise<void> {
  const createApplePaySessionRequest: CreateApplePaySessionRequest = {
    displayName: applePayMerchantName,
    domainName: window.location.host,
  };
  const apiResponse = await createApplePaySession(
    publicKey,
    createApplePaySessionRequest,
    accountId
  );
  const applePaySessionResponse = apiResponse as ApplePaySessionResponse;
  const apiError = apiResponse as RyftApiError;
  if (apiError.code) {
    return;
  }
  try {
    applePaySession.completeMerchantValidation(JSON.parse(applePaySessionResponse.sessionObject));
  } catch (error) {
    sendExceptionToSentry(error);
    console.error(`Error completing merchant validation: ${error}`);
  }
}
