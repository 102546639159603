import { createElement } from './createElement';
import { Styles } from '../../types';

interface SelectOption {
  value: string;
  name: string;
}

interface CreateLabelAndSelectOptions {
  id: string;
  labelText: string;
  options: SelectOption[];
  value: 'code';
  onChangeFunction?: (selectedValue: string) => void;
  defaultValue?: string;
  optional?: boolean;
  styles: Styles;
}

export function createLabelAndSelect({
  id,
  labelText,
  options,
  onChangeFunction,
  defaultValue,
  styles,
}: CreateLabelAndSelectOptions): HTMLElement {
  const div = createElement('div', { id: id });
  div.classList.add('ryft-form-group');
  div.classList.add(id);

  const label = document.createElement('label');
  label.textContent = labelText;
  label.style.color = styles.color;
  div.appendChild(label);

  const select = createElement('select', { id: `${id}-select` }) as HTMLSelectElement;
  select.classList.add('form-control');
  select.style.borderColor = styles.borderColor;
  select.style.color = styles.color;
  select.style.backgroundColor = styles.backgroundColor;
  select.style.borderRadius = styles.borderRadius;
  select.style.padding = styles.padding;

  const initialOption = document.createElement('option');
  initialOption.textContent = 'Select...';
  initialOption.setAttribute('selected', 'true');
  initialOption.setAttribute('disabled', 'true');
  select.appendChild(initialOption);

  options.forEach(option => {
    const optionElem = document.createElement('option');
    optionElem.value = option.value;
    optionElem.textContent = option.name;
    if (option.value === defaultValue) {
      optionElem.selected = true;
    }
    select.appendChild(optionElem);
  });

  select.addEventListener('change', () => {
    const selectedValue = select.value;
    onChangeFunction?.(selectedValue);
  });

  div.appendChild(select);

  const down = document.createElement('div');
  down.classList.add('down-button');
  down.innerHTML = `<svg viewBox="0 0 20 20" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 8l4 4 4-4" stroke="#5A5A5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>`;
  div.appendChild(down);

  return div;
}
