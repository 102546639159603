import { findForm, handleThreeDsRedirectIfCompleted } from '../../utils/';
import { listenForEvents } from './eventListeners';
import { initFieldCollection } from './fieldCollection';
import { initPaymentButtons } from './paymentButtons';
import { initSubscription } from './subscription';
import { initSavedCards } from './savedCards';
import { InitRequest } from '../../types';
import { validateInitRequest } from '../../validators';
import { resetEventHandlers, setInitParams, setSelectedPaymentMethod } from '../../state';
import { mapInitRequest } from './mapInitRequest';
import { initIFrame } from './iframe';
import { initPayGrid } from './payGrid';
import { removePreviousElements } from './removePreviousElements';
import { sendExceptionToSentry, sendErrorToSentry, initSentry } from '../../service';

// Note: this function shouldn't be async as it impacts the state (specifically event handlers)
export function init(request: InitRequest): void {
  try {
    internalInit(request);
  } catch (error) {
    console.error(error);
    sendExceptionToSentry(error);
  }
}

function internalInit(request: InitRequest): void {
  initSentry(request.publicKey, request.clientSecret, request.accountId).catch((): void => {
    console.error(`Ryft.init() lib error tracking unavailable`);
  });
  removePreviousElements();
  resetEventHandlers();
  setSelectedPaymentMethod(undefined);
  const validationResult = validateInitRequest(request);
  if (!validationResult.valid) {
    const message = `Invalid Ryft.init() configuration detected: ${validationResult.error}`;
    sendErrorToSentry(message);
    console.error(message);
    return;
  }
  const initParams = mapInitRequest(request);
  setInitParams(initParams);
  listenForEvents();
  handleThreeDsRedirectIfCompleted();

  let form: HTMLFormElement;
  try {
    form = findForm();
  } catch (error) {
    sendExceptionToSentry(error);
    console.error(error);
    return;
  }
  const iframe = initIFrame(form, initParams);
  initFieldCollection(initParams, iframe);
  const payGrid = initPayGrid(form);
  initSubscription(initParams, iframe, payGrid);
  initSavedCards(initParams, iframe, payGrid);
  initPaymentButtons(initParams, iframe, payGrid).catch((error: unknown): void => {
    const message = error instanceof Error ? error.message : String(error);
    console.error(`Ryft.init() error trying to init apple/google pay: ${message}`);
    sendExceptionToSentry(error);
  });
}
