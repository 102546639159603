import { createElement } from '../domGenerators';
import { initialiseThreeDsChallengeForm } from './initialiseThreeDsChallengeForm';

export function renderThreeDsChallenge(acsUrl?: string, cReq?: string): void {
  const threeDsChallengeIFrameId = 'ryft-threeDSChallengeIframe';
  document.getElementById(threeDsChallengeIFrameId)?.remove();
  const modaliFrame = createElement('iframe', {
    id: threeDsChallengeIFrameId,
    name: 'ryft-threeDSChallengeIframe',
  });
  modaliFrame.classList.add('ryft-threeds-modal-iframe');

  const loadingDiv = document.getElementById('ryft-modal-loading');
  modaliFrame.addEventListener('loadstart', () => {
    if (loadingDiv) {
      loadingDiv.style.display = 'block';
    }
    modaliFrame.style.display = 'none';
  });

  modaliFrame.addEventListener('load', () => {
    if (loadingDiv) {
      loadingDiv.style.display = 'none';
    }
    modaliFrame.style.display = 'block';
  });

  const modalWindow = document.getElementById('ryft-modal-window');
  if (modalWindow) {
    modalWindow.appendChild(modaliFrame);
    const challengeForm = initialiseThreeDsChallengeForm(threeDsChallengeIFrameId, acsUrl, cReq);
    challengeForm.submit();

    document.getElementById('ryft-modal-close')?.remove();
    const closeBtn = createElement('span', { id: 'ryft-modal-close' });
    closeBtn.classList.add('ryft-modal-close');
    closeBtn.textContent = 'Cancel';
    modalWindow.appendChild(closeBtn);
    closeBtn.addEventListener('click', async () => {
      window.postMessage({ type: 'threeDsCancelledEventType' }, '*');
    });
  } else {
    console.error('Modal window element not found!');
  }
}
