﻿import { createApplePayAttemptPaymentRequest } from './createApplePayAttemptPaymentRequest';
import { createApplePayError } from './createApplePayError';
import { internalAttemptPayment, sendWalletPaymentSessionResultEvent } from '../../service';
import { PaymentSession, RyftApiError } from '../../types';

export async function onApplePayPaymentAuthorized(
  publicKey: string,
  clientSecret: string,
  applePayPaymentAuthorizedEvent: ApplePayJS.ApplePayPaymentAuthorizedEvent,
  applePaySession: ApplePaySession,
  storeWalletDetails: boolean,
  accountId?: string
): Promise<void> {
  const attemptPaymentRequest = createApplePayAttemptPaymentRequest(
    clientSecret,
    applePayPaymentAuthorizedEvent,
    storeWalletDetails
  );
  const apiResponse = await internalAttemptPayment(publicKey, attemptPaymentRequest, accountId);
  const paymentSession = apiResponse as PaymentSession;
  const apiError = apiResponse as RyftApiError;
  if (apiError.code) {
    if (apiError.code !== '400') {
      applePaySession.completePayment(ApplePaySession.STATUS_FAILURE);
      return;
    }
    const mappedErrors = apiError.errors.flatMap(element => {
      return createApplePayError(element);
    });
    applePaySession.completePayment({
      status: ApplePaySession.STATUS_FAILURE,
      errors: mappedErrors,
    });
    return;
  }
  if (paymentSession.lastError) {
    applePaySession.completePayment(ApplePaySession.STATUS_FAILURE);
    return;
  }
  sendWalletPaymentSessionResultEvent(paymentSession);
  applePaySession.completePayment(ApplePaySession.STATUS_SUCCESS);
}
