﻿import { deselectPaymentMethod } from './savedCards';
import { getIFrame, getSelectedPaymentMethod } from '../../state';
import {
  sendErrorEvent,
  sendExceptionToSentry,
  sendEventToClient,
  sendWalletPaymentSessionResultEvent,
} from '../../service';
import { handleRequiredAction } from '../handleRequiredAction';
import {
  PaymentSession,
  WalletPaymentRequiredActionEventData,
  walletPaymentRequiredActionEventName,
} from '../../types';
import { AppConfig } from '../../config';

export function listenForEvents(): void {
  listenForIframeEvents();
  listenForBlurEvents();
  listenForWalletPaymentRequiredActionEvents();
}

function listenForIframeEvents(): void {
  // The signature of these functions is different for 'message', so addUniqueEventListener below can't be used here
  window.removeEventListener('message', handleMessageEvent, true);
  window.addEventListener('message', handleMessageEvent, true);
}

function listenForBlurEvents(): void {
  addUniqueEventListener('blur', handleBlurEvent);
}

function listenForWalletPaymentRequiredActionEvents(): void {
  addUniqueEventListener(
    walletPaymentRequiredActionEventName,
    handleWalletPaymentRequiredActionEvent
  );
}

function addUniqueEventListener(type: string, listener: EventListenerOrEventListenerObject): void {
  window.removeEventListener(type, listener, true);
  window.addEventListener(type, listener, true);
}

const handleMessageEvent = (event: MessageEvent): void => {
  const origin = event.origin;
  // Ignore anything that is not from the iframe
  if (origin !== AppConfig.baseUrl) {
    return;
  }

  if (event.data.eventName === walletPaymentRequiredActionEventName) {
    handleWalletPaymentRequiredActionEvent({
      detail: {
        paymentSession: event.data.paymentSession,
      },
    });
  }

  if (event.data.eventName === 'cardFormClicked') {
    const existingSelectedPaymentMethod = getSelectedPaymentMethod();
    deselectPaymentMethod(existingSelectedPaymentMethod, false);
    return;
  }

  if (event.data.eventName) {
    sendEventToClient(event.data.eventName, event.data);
  }
};

const handleBlurEvent = (): void => {
  setTimeout((): void => {
    if (document.activeElement?.id === getIFrame()?.id) {
      const existingSelectedPaymentMethod = getSelectedPaymentMethod();
      deselectPaymentMethod(existingSelectedPaymentMethod, false);
    }
  });
};

const handleWalletPaymentRequiredActionEvent = (
  e: Event | WalletPaymentRequiredActionEventData
): void => {
  const eventData = e as WalletPaymentRequiredActionEventData;
  handleRequiredAction(eventData.detail.paymentSession)
    .then(maybeUpdatedPaymentSession => {
      const updatedPaymentSession = maybeUpdatedPaymentSession as PaymentSession;
      if (updatedPaymentSession.status) {
        sendWalletPaymentSessionResultEvent(updatedPaymentSession);
      }
      if (updatedPaymentSession.lastError) {
        sendErrorEvent(updatedPaymentSession.lastError);
      }
    })
    .catch(error => {
      sendExceptionToSentry(error);
      sendErrorEvent(error?.message || 'An unexpected error occurred, please try again');
    });
};
