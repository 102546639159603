import { IFrameId, InitParams } from '../../types';
import { calculateIframeHeight, constructIframeInitParams } from '../../utils';
import { AppConfig } from '../../config';
import { setIFrame } from '../../state';

export function initIFrame(form: HTMLFormElement, initParams: InitParams): HTMLIFrameElement {
  const iframeHeight = calculateIframeHeight(initParams);
  const iframeDataParams = constructIframeInitParams(initParams);
  const url = `${AppConfig.baseUrl}/${AppConfig.embeddedVersion}?data=${iframeDataParams}`;
  const iframe = document.createElement('iframe');
  iframe.id = IFrameId;
  iframe.src = url;
  iframe.style.height = `${iframeHeight}px`;
  iframe.style.width = '100%';
  iframe.style.border = '0px';
  iframe.style.setProperty('overflow', 'hidden', 'important');
  if (initParams.googlePayConfig && !initParams.googlePayConfig.merchantIdentifier) {
    iframe.allow = 'payment *';
  }
  form.prepend(iframe);
  setIFrame(iframe);
  return iframe;
}
