﻿export function validatePaymentMethodsJson(rawJson: string): boolean {
  try {
    const paymentMethodsResponse = JSON.parse(rawJson);
    if (!('items' in paymentMethodsResponse)) {
      return false;
    }
    const paymentMethods = paymentMethodsResponse.items;
    if (!Array.isArray(paymentMethods)) {
      return false;
    }
    for (const paymentMethod of paymentMethods) {
      if (!('card' in paymentMethod)) {
        return false;
      }
    }
    return true;
  } catch {
    return false;
  }
}
