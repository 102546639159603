import { InternalAttemptPaymentRequest } from '../../types';
import { getThreeDsRequestDetails } from '../getThreeDsRequestDetails';

export function createGooglePayAttemptPaymentRequest(
  clientSecret: string,
  paymentData: google.payments.api.PaymentData,
  storeWalletDetails: boolean
): InternalAttemptPaymentRequest {
  const googlePayToken = paymentData.paymentMethodData.tokenizationData.token;
  const attemptPaymentRequest: InternalAttemptPaymentRequest = {
    clientSecret,
    walletDetails: {
      type: 'GooglePay',
      googlePayToken,
    },
    paymentMethodOptions: {
      store: storeWalletDetails,
    },
    threeDsRequestDetails: getThreeDsRequestDetails(),
  };
  const address = paymentData.paymentMethodData.info?.billingAddress;
  const postalCode = address?.postalCode || undefined;
  const country = address?.countryCode || undefined;
  const region = address?.administrativeArea || undefined;
  const lineOne = address?.address1 || undefined;
  const lineTwo = address?.address2 || undefined;
  const city = address?.locality || undefined;
  const nameArr = address?.name?.split(' ') || [];
  const titleArr = ['mr', 'mrs', 'ms', 'dr', 'master', 'sir'];
  const firstName = titleArr.includes(nameArr[0].toLowerCase())
    ? nameArr[1]
    : nameArr[0] || undefined;
  const lastName = nameArr[nameArr.length - 1] || undefined;
  if (country && postalCode) {
    attemptPaymentRequest.billingAddress = {
      firstName,
      lastName,
      lineOne,
      lineTwo,
      city,
      country,
      postalCode,
      region,
    };
  }
  const email = paymentData.email;
  if (email) {
    attemptPaymentRequest.customerDetails = {
      email,
    };
  }
  return attemptPaymentRequest;
}
